import React from 'react';
import { Container, Typography, Box, Link, Avatar, IconButton } from '@mui/material';
import xiaohongshuIcon from './img/XiaohongshuLOGO.png'
import Instagram from '@mui/icons-material/Instagram';
import { email, instagramUrl, redbookUrl } from '../constants/socials';

export default function Footer() {
    return (
        <Box component="footer" sx={{ bgcolor: '#FF9696', py: 4 }} display={'flex'} justifyContent={'center'}>

            <Box
                className='contentArea'
                width={'100%'}
                maxWidth='1920px'
            >
                <Container maxWidth="xlg">
                    <Typography
                        fontSize={{ xs: 30, sm: 40, lg: 40 }}
                        fontFamily={"Baloo2-ExtraBold"}
                        align="left"
                    > {"AiryVibe"} </Typography>
                    <IconButton
                        onClick={() => {
                            window.open(redbookUrl, '_blank')
                        }}>
                        <Avatar src={xiaohongshuIcon} alt="小红书" style={{ width: 40, height: 40 }} />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            window.open(instagramUrl, '_blank')
                        }}>
                        <Instagram style={{ width: 40, height: 40 }} />
                    </IconButton>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="text.secondary"
                        component="p"
                    >
                        Email: {email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'© '}
                        <Link color="inherit" href="https://www.airyvibe.com/">
                            AIRYVIBE
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};