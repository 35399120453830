import React from 'react';
import { Select, TextField, MenuItem, Box } from '@mui/material';
import { pink } from '../../airyVibeTheme.js'

export function MySelect({ placeholder, value, selections = [], handler }) {
    return (
        <Box>
            <Select
                size='small'
                value={value}
                onChange={(e) => { e.preventDefault(); handler(e.target.value); }}
                sx={{
                    width: "140px",
                    borderRadius: "45px",
                    color: "black",
                    textAlign: 'center',
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255,150,150,0.4)"
                    },
                    "& .MuiSelect-iconOutlined": {
                        color: "rgba(255,150,150,0.4)"
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF7A7A"
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF7A7A"
                    },
                    "& .MuiOutlinedInput-input": {
                        color: "#FF7A7A"
                    }
                }}
                displayEmpty={placeholder ? true : false}
            >
                {placeholder && <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>}
                {selections.map((text) => (<MenuItem key={'selection-option-' + text} value={text} style={{
                    textAlign: "center",
                    color: "#FF7A7A"
                }}>{text}</MenuItem>))}
            </Select>
        </Box>
    );
}

export function MyInput({ label, value, handler }) {
    return (
        <>
            <TextField
                size='small'
                value={value}
                label={label}
                onChange={(e) => { e.preventDefault(); handler(e.target.value); }}
                sx={{
                    width: "140px",
                    color: "black",
                    textAlign: 'center',
                    '& .MuiOutlinedInput-root': { borderRadius: '40px' },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255,150,150,0.4)"
                    },
                    "& .Mui-focused  fieldset": {
                        borderColor: "#FF7A7A !important"
                    },
                    "& .MuiOutlinedInput-input": {
                        color: "#FF7A7A !important"
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF7A7A"
                    },
                    "& label": {
                        color: "#FF7A7A"
                    },
                    "& label.Mui-focused": {
                        color: "#FF7A7A"
                    }
                }}
            >
            </TextField >
        </>
    );
}


export function AliceSelect({ placeholder, value, selections = [], handler, handlerByIndex, width = '110px' }) {
    return (
        <Box>
            <Select
                size='small'
                value={value}
                onChange={(e) => {
                    e.preventDefault();
                    if (handler) {
                        handler(e.target.value);
                    }
                    if (handlerByIndex) {
                        const selectedIndex = selections.indexOf(e.target.value);
                        handlerByIndex(selectedIndex);
                    }
                }}
                sx={{
                    width: width,
                    height: "26px",
                    borderWidth: "0.5px",
                    color: `${pink[2]}`,
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255,150,150,0.4)",
                        border: '0.5px solid',
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255,150,150,0.4)",
                        border: '1px solid',
                    },

                    "& .Mui.MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255,150,150,0.4)",
                        border: '0.5px solid',
                    },
                    "& .MuiSelect-iconOutlined": {
                        color: "rgba(255,150,150,0.4)"
                    },
                    "& .Mui-focused": {
                        borderColor: "rgba(255,150,150,0.4)",
                        border: '0.5px solid',
                    },
                    "& .MuiOutlinedInput-input": {
                        color: "#FF7A7A"
                    },
                }}
                displayEmpty={placeholder ? true : false}
            >
                {placeholder && <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>}
                {selections.map((text) => (<MenuItem key={'selection-option-' + text} value={text} sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '16px',
                    py: 0,
                    minHeight: '38px',
                    minWidth: '110px',
                    textAlign: "center",
                    color: "#FF7A7A"
                }}>{text}</MenuItem>))}
            </Select>
        </Box >
    );
}