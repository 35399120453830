import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';
export default function SimpleDialog(props) {
    const { onClose, title, content, open, ...other } = props;
    const radioGroupRef = React.useRef(null);
    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    const handleCancel = () => {
        onClose();
    };
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {content.map((line, index) => (<p key={'dialog-text-' + index}>{line}</p>))}
                <Button
                    autoFocus
                    variant={'contained'}
                    onClick={handleCancel}
                    fullWidth
                    sx={{ borderRadius: '80px' }}
                >
                    Close
                </Button>
            </DialogContent>

        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    content: PropTypes.arrayOf(String).isRequired,
};
