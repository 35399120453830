import * as React from 'react';
import { Box } from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MyStudioInfoForm from './MyStudioInfoForm';
import StudioNameForm from '../Profile/StudioNameForm';
import { getUserAndData } from '../User';
import MyLogoAndCoverForm from './MyLogoAndCoverForm';
import MyStudioSummaryForm from './MyStudioSummaryForm';
import { AgreementComponent } from '../AgreementForm';
import { signAgreement, studioRegister } from '../apis';
import SimpleDialog from '../SimpleDialog';
import { Auth } from 'aws-amplify';
import { Logout } from '@mui/icons-material';

async function signOut() {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

function StudioRegisterPage() {
    return (
        <>
            <Typography variant='h4' align='center' margin={'16px'}>
                激活工作室
            </Typography>
            <Box textAlign={'right'}>

                <Button variant='contained'
                    onClick={() => { signOut() }}> <Logout></Logout>登出账户</Button>
            </Box>

            <Box
                maxWidth={'800px'}
                m={1}
            >
                <Box id={'StudioRegisterPage'}>
                    <VerticalLinearSetupStepper />
                </Box>
            </Box>
        </>
    );
}
export default StudioRegisterPage;

export function VerticalLinearSetupStepper() {
    const requestedRef = React.useRef(false);
    let userData = null;
    const [user, setUser] = React.useState(null);
    const [farthestStep, setFarthestStep] = React.useState(0);
    const [agreementContent, setAgreementContent] = React.useState("");
    const agreementVersion = 1;
    const agreementFilePath = './agreements/photographerAgreement_' + agreementVersion + '.txt';

    const [successDialogTitle,] = React.useState('Congratulations');
    const [successDialogContentArray, setSuccessDialogContentArray] = React.useState([]);
    const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);


    function redirectToMyStudio(message) {
        if (message === undefined) {
            message = `Congratulations! You completed the studio registration.
            Now taking you to "My Studio".`;
        }
        setSuccessDialogContentArray(message.split('\n'));
        // show the message above
        setSuccessDialogOpen(true);
        // TODO: set a firework
        const timer = setTimeout(() => {
            const redirectUrl = '/mystudio';
            window.location.href = redirectUrl;
        }, 3000); // Close the page after 3 seconds
        return () => clearTimeout(timer); // Cleanup
    }

    React.useEffect(() => {
        if (requestedRef.current) return;
        requestedRef.current = true;
        loadUserData(true);
        // auto redirect on success
        fetchTextContent();
    }, []);
    const fetchTextContent = async () => {
        try {
            const response = await fetch(agreementFilePath);
            const data = await response.text();
            setAgreementContent(data);
        } catch (error) {
            console.error('Error fetching text content:', error);
        }
    };
    function downloadAgreementCallback() {
        const downloadLink = document.createElement('a');
        downloadLink.href = agreementFilePath;
        downloadLink.download = 'photographerAgreement.txt';
        downloadLink.click();
    }
    function submitAgreementCallback() {
        signAgreement(user, agreementFilePath)
            .then(response => {
                const dataObject = JSON.parse(response);
                if (dataObject.statusCode !== 200) {
                    console.error('sign agreement failed, response is:', JSON.stringify(dataObject));
                    throw new Error("Request failed: " + dataObject.body.error);
                }
                console.log('sign agreement success.')
                loadUserData(false);
                studioRegister(user)
                    .then(response => {
                        const dataObject = JSON.parse(response);
                        if (dataObject.statusCode !== 200) {
                            console.error('register studio failed, response is:', JSON.stringify(dataObject));
                            throw new Error("Request failed: " + dataObject.body.error);
                        }
                        console.log('register studio success.');
                        console.log('refresh point.');
                        redirectToMyStudio();
                    })
                    .catch((e) => {
                        console.log('ERROR! egister studio failed.');
                        console.error(e)
                    });
            })
            .catch((e) => {
                console.log('ERROR! sign agreement failed.');
                console.error(e)
            });
    }
    async function loadUserData(autoMoveToFarthest) {
        const userAndData = await getUserAndData();
        setUser(userAndData.user);
        if (userAndData.user === null) {
            // is no user sign in, redirect to platform landing page
            const timer = setTimeout(() => {
                const redirectUrl = '/';
                window.location.href = redirectUrl;
            }, 100);
            return () => clearTimeout(timer);
        }
        userData = userAndData.userData;
        if ('studioRegister' in userData) {
            redirectToMyStudio();
        }
        readStep(autoMoveToFarthest);
    }
    function readStep(autoMoveToFarthest) {
        if (userData.cover && userData.logo) {
            setFarthestStep(4); // step 4 is done.
            console.log('set fartest to 4');
            if (autoMoveToFarthest) {
                setActiveStep(4);
            }
            return;
        }
        if (userData.basicInfo && userData.basicInfo.address1 && userData.basicInfo.addressCity
            && userData.basicInfo.addressState && userData.basicInfo.addressZip) {
            setFarthestStep(3); // step 3 is done.
            console.log('set fartest to 3');
            if (autoMoveToFarthest) {
                setActiveStep(3);
            }
            return;
        }
        if (userData.studioSummary && userData.photographerSummary) {
            setFarthestStep(2); // step 2 is done.
            console.log('set fartest to 2');
            if (autoMoveToFarthest) {
                setActiveStep(2);
            }
            return;
        }
        if (userData.studioName && userData.photographerName) {
            setFarthestStep(1); // step 0 is done.
            console.log('set fartest to 1');
            if (autoMoveToFarthest) {
                setActiveStep(1);
            }
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    function submitCallback() {
        let autoMoveToFarthest = false;
        if (farthestStep === activeStep) {
            autoMoveToFarthest = true;
        }
        loadUserData(autoMoveToFarthest);
    }

    const steps = [
        {
            label: '设置工作室名',
            estimate: 1,
            child: <StudioNameForm user={user} submitCallback={submitCallback} />
        },
        {
            label: '设置简介',
            estimate: 5,
            child: <MyStudioSummaryForm user={user} submitCallback={submitCallback} />
        },
        {
            label: '设置工作室地址信息',
            estimate: 1,
            description: '',
            child: <MyStudioInfoForm user={user} submitCallback={submitCallback} />
        },
        {
            label: '设置logo和工作室封面图',
            estimate: 3,
            child: <MyLogoAndCoverForm user={user} submitCallback={submitCallback} showStatus={true} maxWidth={300} />
        },
        {
            label: '服务协议',
            estimate: 3,
            child: <AgreementComponent
                agreementContent={agreementContent}
                downloadCallback={downloadAgreementCallback}
                submitCallback={submitAgreementCallback}
            />
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ maxWidth: '800px' }}>
            <SimpleDialog
                id="simple-dialog"
                open={successDialogOpen}
                title={successDialogTitle}
                content={successDialogContentArray}
                onClose={() => { setSuccessDialogOpen(false) }}
            ></SimpleDialog>
            <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                    "& .Mui-completed": {
                        color: "green"
                    },
                }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                step.estimate ? (
                                    <Typography variant="caption">预计用时 {step.estimate} 分钟</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            {step.description && <Typography>{step.description}</Typography>}
                            <Box
                                sx={{
                                    border: 'solid 1px rgba(0,0,0,0.2)',
                                    borderRadius: '6px',
                                    py: '16px',
                                    maxWidth: "600px"
                                }}>
                                {step.child}
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        disabled={activeStep === farthestStep}
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    );
}