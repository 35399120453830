import React, { useEffect, useRef, useState } from 'react';
import { Box, Rating, Typography, Button, Snackbar, Alert, Grid, Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import ServiceDisplayBox from './ServiceDisplayBox';
import { getStudioBookedTime, getStudioData } from './apis';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChatBotButton from './SpeedDialButton/ChatBotButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slider.css"
import { ronWechat } from '../constants/socials';
import { reviewTimeFormat, readUTCTime } from './commonUtils';
import { useTranslation } from 'react-i18next';
import ImgListDialog from './ImgDialog/ImgListDialog';

function calculateAverageRatingFromAllReviews(reviews) {
    if (!reviews || reviews.length === 0) return 0;
    const { totalRating, ratingCount } = reviews.reduce((accumulator, review) => {
        review.rates.forEach(rate => {
            accumulator.totalRating += rate.rate;
            accumulator.ratingCount++;
        });
        return accumulator;
    }, { totalRating: 0, ratingCount: 0 });
    const averageRating = ratingCount > 0 ? totalRating / ratingCount : 0;
    return averageRating.toFixed(1);
}

function extractAllImageUrlsFromServiceData(serviceData) {
    const imageURLs = [];
    if (serviceData && serviceData.length > 0) {
        serviceData.forEach((item) => {
            if (item.images && item.images.length > 0) {
                item.images.forEach((image) => {
                    if (image.url) {
                        imageURLs.push(image.url);
                    }
                });
            }
        });
    }
    return imageURLs;
}

function StudioPage() {
    const { t } = useTranslation();
    let params = useParams();
    const studioName = params.studioName;
    const [studioData, setStudioData] = useState({});
    const [studioBookedTime, setStudioBookedTime] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const dataLoadedRef = useRef(false);

    // image dialog related
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDialogSrc, setimageDialogSrc] = useState([]);
    const [imageDialogIndex, setimageDialogIndex] = useState(0);
    let imgDialogHandleClose = () => { setImageDialogOpen(false) };
    const callImageDialog = (images, index) => {
        setimageDialogSrc(images)
        setimageDialogIndex(index)
        setImageDialogOpen(true)
    }

    // snackbar for copy
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: true, // Enables center mode
        centerPadding: '0', // Removes padding around the center image
        nextArrow: <ArrowForwardIosIcon fontSize="large" color='dark' />,
        prevArrow: <ArrowBackIosOutlinedIcon fontSize="large" color='dark' />,
    };
    async function loadStudioData() {
        if (dataLoadedRef.current) return;
        dataLoadedRef.current = true;
        const studioDataResponse = JSON.parse(JSON.parse(await getStudioData(studioName, true)));
        if (studioDataResponse.statusCode === 200) {
            if (!studioDataResponse.studioData['./agreements/photographerAgreement_1.txt']) {
                console.error('Platform Aggreement is not signed by this studio.');
                return;
            }
            console.log(studioDataResponse.studioData);
            setStudioData(studioDataResponse.studioData);
            // temporarily have all images load into carouselImages for demo
            setCarouselImages(extractAllImageUrlsFromServiceData(studioDataResponse.studioData.serviceData));
        } else {
            console.error(studioDataResponse)
        }
        const bookedTimeResponse = JSON.parse(await getStudioBookedTime(studioName));
        setStudioBookedTime(bookedTimeResponse);
    }
    useEffect(() => {
        loadStudioData()
    }, [])

    const aRate = calculateAverageRatingFromAllReviews(studioData.review ?? []);
    return (
        <Box className='StudioPage'>
            {JSON.stringify(studioData) === '{}' ?
                <p>loading...</p> :
                <Box
                    sx={{ px: 1, padding: { xs: "3px", md: 2 } }}
                    className='pageContent'
                    maxWidth='1920px'
                >
                    <ChatBotButton studioName={studioName}></ChatBotButton>
                    <Box id='SliderBox'
                        style={{
                            padding: "auto",
                            margin: "auto",
                            width: "inherit",
                            maxWidth: '100vw',
                            marginBottom: "20px"
                        }}>
                        {carouselImages.length === 0 ? <></> :
                            <Slider {...settings}>
                                {carouselImages.map((imageUrl, index) => (
                                    <div key={`carousel-div-${index}`} >
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                alt={`carousel - ${index}`}
                                                src={imageUrl}
                                                style={{ maxHeight: '85vh', maxWidth: '100vw', height: 'auto', width: 'auto' }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        }
                    </Box>
                    <Box id='SummaryBox' justifyContent={'center'} display={'flex'} width={'90vw'} marginX='auto' marginY={4}>
                        <Box id='StudioLogoBox' sx={{ width: { xs: 80, md: 110, lg: 140, xl: 170 } }}>
                            <img alt='studio-logo' src={studioData['logo']} width={'100%'}
                                style={{ borderRadius: '100px' }}></img>
                        </Box>
                        <Box id='StudioSummaryBox' marginLeft={"32px"}>
                            <Grid container direction={'row'}>
                                <Grid item xs={12} md={4}>
                                    <Typography>{t('constant.photographer')}: {studioData['photographerName']}</Typography>
                                </Grid>
                                <Grid item xs={8} md={4}>
                                    <Rating
                                        name="star-rating"
                                        value={Number(aRate)}
                                        precision={0.1}
                                        sx={{ "& .MuiRating-icon": { color: "#FF9696" } }}
                                        readOnly
                                        display='inline-block'
                                        size='small'
                                    />
                                    <Typography
                                        flexGrow={1}
                                        noWrap
                                        textAlign={'left'}
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        whiteSpace="nowrap"
                                        fontSize={'12px'}
                                        display='inline-block'>
                                        {aRate}({studioData.review?.length ?? 0})
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        onClick={() => {
                                            handleClickSnackbar();
                                            setSnackbarErrorMessage(t('constant.wechat') + ": " + ronWechat);
                                        }}
                                        sx={{
                                            width: '73px',
                                            borderRadius: '50px',
                                            paddingY: '0px',
                                            color: 'black',
                                            backgroundColor: "#D9D9D9",
                                            '&:hover': {
                                                backgroundColor: '#c9c9c9',
                                                boxShadow: 'none',
                                            },
                                        }}>{t('studio.contactSupport')}</Button>
                                </Grid>
                            </Grid>

                            <Box id='StudioSummaryBox-row-1' display="flex" alignItems="center">
                                <Typography>{t('studio.studioSummary')}: {studioData['studioSummary']}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="ServiceBox">
                        {studioData['serviceData']
                            ? <ServiceDisplayBox
                                serviceData={studioData['serviceData']}
                                studioName={studioName}
                                openHours={studioData['timeData']}
                                studioBookedTime={studioBookedTime}
                            />
                            : "No service providing."
                        }
                    </Box>
                    <Box id="CommentBox">
                        <ReviewSection reviews={studioData.review ?? []} callImageDialog={callImageDialog} />
                    </Box>
                </Box>}
            <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarErrorMessage}
                </Alert>
            </Snackbar>
            <ImgListDialog
                imageDialogOpen={imageDialogOpen}
                imgDialogHandleClose={imgDialogHandleClose}
                imageDialogSrc={imageDialogSrc}
                index={imageDialogIndex}
            />
        </Box >
    );
}
export default StudioPage;

function ReviewSection({ reviews, callImageDialog }) {
    // TODO: request user_name and display
    const { t } = useTranslation();
    if (reviews === undefined)
        return (<>loading</>)
    if (reviews.length === 0)
        return (<>No reviews</>)
    const reviewImages = reviews.map(review => review.images.map(image => image.imageSignedUrl));
    function hasProduct(index) {
        return reviews[index].product_list?.length > 0 ?? false;
    }
    return (
        reviews.map((review, index) => (
            <Box key={"review" + index} sx={{ marginX: { xs: 1, md: 2 }, marginBottom: 1 }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Avatar>U</Avatar>
                    <Typography variant="p1">{review.customer_id}</Typography>
                </Box>
                <RatingComponent review={review} />
                <Box>
                    <Typography variant="caption" fontSize={12}>{
                        hasProduct(index)
                            ? t('service') + ':' + review.product_list[0].product_name
                            : ''
                    }</Typography>
                </Box>
                <Box>
                    <Typography variant="p1">{review.text}</Typography>
                </Box>
                {review.images.length > 0 &&
                    <Box
                        key={'img-box-' + index}
                        display={'flex'}
                        flexWrap={'wrap'}
                        justifyContent={'flex-start'}
                        maxWidth={'95vw'}>
                        {review.images.map((image, index2) => (
                            <Box
                                key={'img-' + index2}
                                width={150}
                                height={150}
                                border={'solid #80808066 1px'}
                                m={1}
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                onClick={() => {
                                    callImageDialog(reviewImages[index], index2)
                                }}
                            >
                                <img
                                    src={image.imageSignedUrl}
                                    alt={'review img'}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        width: 'auto',
                                        height: 'auto',
                                        alignSelf: 'center',
                                    }} />
                            </Box>))
                        }
                    </Box>
                }
                <Box>
                    <Typography variant="caption">
                        {readUTCTime(review.createdUTCTime, reviewTimeFormat, 'YYYY-MM-DD').local}
                    </Typography>
                </Box>
            </Box>
        ))
    )
}

const RatingComponent = ({ review }) => {
    const { t } = useTranslation();
    return (
        <Box>
            {review.rates.map((rate, index) => (
                <Box key={'rate' + index} display="flex" alignItems="center">
                    <Typography fontSize={14} fontWeight={300} variant="p1" mr={1}>
                        {t('studio.ratingCategory.' + rate.name)}
                    </Typography>
                    <Rating
                        name={`star-rating-${index}`}
                        value={rate.rate}
                        precision={0.1}
                        sx={{ "& .MuiRating-icon": { color: "#FF9696" } }}
                        readOnly
                        size='small'
                    />
                </Box>
            ))
            }
        </Box >
    );
};
