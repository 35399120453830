import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Typography, FormControlLabel, Radio, Button, TextField } from '@mui/material';

AgreementComponent.propTypes = {
    agreementContent: PropTypes.string.isRequired,
    downloadCallback: PropTypes.func.isRequired,
    submitCallback: PropTypes.func.isRequired,
};

export function AgreementComponent(props) {
    /* This is meant to be a general agreement accept form,
     please define the submit handler callback from the parent component.
     */
    const { agreementContent, downloadCallback, submitCallback } = props;
    const [isAgreed, setIsAgreed] = useState(false);

    const handleAgreementChange = () => {
        console.log(isAgreed)
        setIsAgreed(!isAgreed);
    };

    const handleDownloadClick = () => {
        console.log('下载按钮被点击');
        downloadCallback();
    };

    const handleSubmitClick = () => {
        if (isAgreed) {
            console.log('提交按钮被点击');
            submitCallback();
        } else {
            alert('请先同意协议');
        }
    };

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                用户协议
            </Typography>
            <TextField
                multiline
                maxRows={20}
                variant="filled"  // 或者使用 'filled'，'standard'，根据你的设计风格
                fullWidth
                InputProps={{
                    readOnly: true,  // 设置文本框为只读
                }}
                sx={{
                    overflowY: 'scroll',  // Make the vertical scrollbar always visible
                }}
                value={agreementContent}
            />

            <FormControlLabel
                control={<Radio color="primary" checked={isAgreed} onChange={handleAgreementChange} />}
                label="我已阅读并同意上述协议"
            />

            <Button variant="outlined" color="primary" onClick={handleDownloadClick}>
                下载协议
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
                disabled={!isAgreed}>
                提交
            </Button>
        </div>
    );
};

