export const GA_MEASUREMENT_ID = 'G-C318698HEC';

// Initialize gtag function if it doesn't exist
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }

// Send initial pageview
gtag('js', new Date());
gtag('config', GA_MEASUREMENT_ID);

// Function to log page views
export const logPageView = (url) => {
    gtag('config', GA_MEASUREMENT_ID, {
        page_path: url,
    });
};

// Function to log specific events
export const logEvent = ({ action, category, label, value }) => {
    gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};

export const logReferral = (refValue) => {
    if (refValue) {
        logEvent({
            action: 'referral',
            value: refValue
        });
    }
};