import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    function loadUser() {
        Auth.currentAuthenticatedUser()
            .then(user => { setUser(user); })
            .catch(err => { console.error(err); return null; });
    }
    useEffect(() => {
        loadUser();
    }, [])
    useEffect(() => {
        // Redirect the user after 3 seconds
        if (!user) return;
        const timer = setTimeout(() => {
            navigate('/');
        }, 3000);
        return () => clearTimeout(timer); // Cleanup
    }, [navigate, user]);

    return (
        <div className='LoginPage'>
            <Box sx={{ px: 1 }} className='pageContent'>
                {user ? (<>You are login already.<br /> Auto redirect to Home Page in 3 seconds...</>) : <Authenticator />}
            </Box>
        </div>
    );
}
export default LoginPage;