import React from 'react';
import { Box } from '@mui/material';
import "./TimeTable.css";
import TimeTable48 from './TimeTable48';

function SevenFourtyEightTimeTable({ timeSelected, setTimeSeleted }) {
    return (
        <Box className='SevenFourtyEightTimeTable'>
            <Box
                className='TimeTable'
                width={"100%"}
                display={"flex"}
            >
                <div className='day-cell'></div>
                <div className='day-cell'>Sun</div>
                <div className='day-cell'>Mon</div>
                <div className='day-cell'>Tue</div>
                <div className='day-cell'>Wed</div>
                <div className='day-cell'>Thur</div>
                <div className='day-cell'>Fri</div>
                <div className='day-cell'>Sat</div>
            </Box>
            <Box display={"flex"}>
                <Box className='TimeTable' width={"12.5%"}>
                    <div className='timestamp-cell'>0:00</div>
                    <div className='timestamp-cell'>0:30</div>
                    <div className='timestamp-cell'>1:00</div>
                    <div className='timestamp-cell'>1:30</div>
                    <div className='timestamp-cell'>2:00</div>
                    <div className='timestamp-cell'>2:30</div>
                    <div className='timestamp-cell'>3:00</div>
                    <div className='timestamp-cell'>3:30</div>
                    <div className='timestamp-cell'>4:00</div>
                    <div className='timestamp-cell'>4:30</div>
                    <div className='timestamp-cell'>5:00</div>
                    <div className='timestamp-cell'>5:30</div>
                    <div className='timestamp-cell'>6:00</div>
                    <div className='timestamp-cell'>6:30</div>
                    <div className='timestamp-cell'>7:00</div>
                    <div className='timestamp-cell'>7:30</div>
                    <div className='timestamp-cell'>8:00</div>
                    <div className='timestamp-cell'>8:30</div>
                    <div className='timestamp-cell'>9:00</div>
                    <div className='timestamp-cell'>9:30</div>
                    <div className='timestamp-cell'>10:00</div>
                    <div className='timestamp-cell'>10:30</div>
                    <div className='timestamp-cell'>11:00</div>
                    <div className='timestamp-cell'>11:30</div>
                    <div className='timestamp-cell'>12:00</div>
                    <div className='timestamp-cell'>12:30</div>
                    <div className='timestamp-cell'>13:00</div>
                    <div className='timestamp-cell'>13:30</div>
                    <div className='timestamp-cell'>14:00</div>
                    <div className='timestamp-cell'>14:30</div>
                    <div className='timestamp-cell'>15:00</div>
                    <div className='timestamp-cell'>15:30</div>
                    <div className='timestamp-cell'>16:00</div>
                    <div className='timestamp-cell'>16:30</div>
                    <div className='timestamp-cell'>17:00</div>
                    <div className='timestamp-cell'>17:30</div>
                    <div className='timestamp-cell'>18:00</div>
                    <div className='timestamp-cell'>18:30</div>
                    <div className='timestamp-cell'>19:00</div>
                    <div className='timestamp-cell'>19:30</div>
                    <div className='timestamp-cell'>20:00</div>
                    <div className='timestamp-cell'>20:30</div>
                    <div className='timestamp-cell'>21:00</div>
                    <div className='timestamp-cell'>21:30</div>
                    <div className='timestamp-cell'>22:00</div>
                    <div className='timestamp-cell'>22:30</div>
                    <div className='timestamp-cell'>23:00</div>
                    <div className='timestamp-cell'>23:30</div>
                </Box>
                <Box className='TimeTable' width={"87.5%"}>
                    <TimeTable48 data={timeSelected} changeHandler={setTimeSeleted} />
                </Box>
            </Box>
        </Box >
    );
}
export default SevenFourtyEightTimeTable;