import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Select, InputLabel, MenuItem, FormControl, IconButton } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import { getUserAndData } from '../User';
import { useTranslation } from 'react-i18next';
const socialMediaSelection = ['Instagram', 'Redbook', 'Facebook'];

function SocialMediaRow({ socialMediaItem, rowIndex, changeHandler, removeRow }) {
    const { t } = useTranslation();
    return (
        <Box flexDirection='row' display='flex' gap={1}>
            <IconButton
                onClick={() => { removeRow(rowIndex); }}
            >
                <CancelIcon color='red' />
            </IconButton>
            <Box display='flex' flexDirection='column' gap={1}>
                <FormControl >
                    <InputLabel id="platform-select-label">Platform</InputLabel>
                    <Select
                        sx={{ minWidth: '140px' }}
                        size='small'
                        labelId="platform-select-label"
                        label={t('mystudio.socialMedia')}
                        id="platform-select"
                        value={socialMediaItem.platform}
                        onChange={(e) => {
                            let copy = JSON.parse(JSON.stringify(socialMediaItem));
                            copy.platform = e.target.value;
                            changeHandler(rowIndex, copy);
                        }}
                    >
                        {socialMediaSelection.map((platform) => (
                            <MenuItem key={'row-' + rowIndex + '-platform-menu-item-' + platform} value={platform}>{platform}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <TextField
                        size='small'
                        label={t('mystudio.accountID')}
                        value={socialMediaItem.accountID}
                        sx={{ width: 'auto' }}
                        onChange={(e) => {
                            let copy = JSON.parse(JSON.stringify(socialMediaItem));
                            copy.accountID = e.target.value;
                            changeHandler(rowIndex, copy);
                        }}
                    />
                </FormControl>
            </Box>
        </Box>
    );
}

function MyStudioSocialMediaForm({ user }) {
    const { t } = useTranslation();
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    const [socialMediaData, setSocialMediaData] = useState([]);
    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    let requested = false;
    function socialMediaDataChangeHandler(socialMediaItemIndex, newSocialMediaItem) {
        let copy = JSON.parse(JSON.stringify(socialMediaData));
        copy[socialMediaItemIndex] = JSON.parse(JSON.stringify(newSocialMediaItem));
        setSocialMediaData(copy);
    }
    function handleAddSocialMediaRow() {
        setSocialMediaData([...socialMediaData, { platform: '', accountID: '' }]);
    }
    function removeRow(rowIndex) {
        if (rowIndex >= socialMediaData.length) return;
        let copy = JSON.parse(JSON.stringify(socialMediaData));
        copy.splice(rowIndex, 1);
        setSocialMediaData(copy);
    }
    function loadForm(form) {
        if (!form) {
            return;
        }
        setSocialMediaData(form);
    }
    async function updateUserData(originalUserData, updatedUserData) {
        if (!user) {
            console.error('did not login yet')
            return;
        }
        const requestBody = {
            path: 'updateUserData', accessToken: user.signInUserSession.accessToken.jwtToken,
            originalUserData: originalUserData, updatedUserData: updatedUserData
        };
        let apiResponse = undefined;
        await fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/userAuth',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(requestBody),
            }).then(function (response) {
                console.log(response);
                return response.text();
            }).then(response => {
                apiResponse = response;
                console.log(apiResponse);
            })
            .catch(error => {
                console.error(error);
            });
        return apiResponse;
    }
    function hasEmptyString(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        for (let index = 0; index < socialMediaData.length; index++) {
            const socialMediaItem = socialMediaData[index]
            if (hasEmptyString(socialMediaItem)) {
                showErrorDialog(444, 'Please fill in all blanks');
                return;
            }
        }
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['socialMediaData'] = JSON.parse(JSON.stringify(socialMediaData));
        console.log(updatedUserData);
        const apiResponse = JSON.parse(await updateUserData(userData, updatedUserData));
        console.log(apiResponse);
        console.log(apiResponse['statusCode']);
        console.log(apiResponse['statusCode'] === 200);
        if (apiResponse['statusCode'] === 200) {
            window.location.reload();
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['errorMessage'])
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('socialMediaData' in userAndData.userData) {
                console.log('socialMediaData exists');
                loadForm(userAndData.userData.socialMediaData);
            } else {
                console.log('not found')
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (
        <Box id={'MyStudioSocialMediaForm'}>
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                    <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                        <Button
                            variant="contained"
                            color="pink"
                            onClick={handleAddSocialMediaRow}
                            sx={{ width: 200 }}
                        >
                            {t('mystudio.addNew')}
                        </Button>
                        {socialMediaData.map((socialMediaItem, index) => (
                            <SocialMediaRow
                                key={'social-media-row-' + index}
                                socialMediaItem={socialMediaItem}
                                rowIndex={index}
                                changeHandler={socialMediaDataChangeHandler}
                                removeRow={removeRow}
                            />
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t('mystudio.save')}
                        </Button>
                    </Box>
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyStudioSocialMediaForm;