import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

function FAQComponent() {
    const faqs = [
        {
            question: '摄影师和平台的关系',
            answer: '摄影师是平台的服务商，不存在雇佣关系。这意味着摄影师不是平台的雇员，而是作为自由职业者或外部服务提供者提供服务。在这种模式下，平台提供客户匹配、支付处理和市场营销等服务，而摄影师则提供专业的摄影服务。'
        },
        {
            question: '平台对摄影师的拍照风格会有要求？',
            answer: '客户会根据您的样片和摄影风格选择服务，平台对摄影师没有具体的拍摄风格要求。'
        },
        {
            question: '平台如何确保摄影作品的版权和使用权？',
            answer: '摄影师拥有摄影作品的版权，平台拥有摄影师作品的使用权用于服务终端客户和宣传目的。'
        },
        {
            question: '平台如何处理退款和取消订单？',
            answer: '终端客户在预约拍摄时间15天前可以选择全额退款取消订单。预约拍摄时间15天以内取消订单退80%，平台会保留剩余20%，视为对服务提供商已经投入的时间和资源的一种合理补偿以及支付平台的交易成本。'
        },
        {
            question: '沟通机制',
            answer: '前期下单前终端客户和客服沟通问题，客服负责找摄影师确认答案后回复客户。下单后客服会创建微信群让客户和摄影师直接沟通，客服负责监督沟通过程确保客户和摄影师双方的权益得到保障。后期下单前终端客户和摄影师的AI客服沟通，遇到AI客服回答不了的答案将会把问题转发到摄影师微信里由摄影师回答（AI客服功能需要开发和细化）。'
        },
        {
            question: '如何处理客户投诉和纠纷？',
            answer: '平台客服在接到客户投诉后深入了解投诉的原因，区分是服务问题、用户误解还是其他原因。然后根据具体原因帮助客户和摄影师沟通，争取和平解决问题。我们目标是协助摄影师为终端客户提供真实满意的服务。'
        },
        {
            question: '加入平台收费吗？',
            answer: '注册平台创建展示页面不收费，我们仅收取交易费用15%的手续费用于支付Stripe交易费用，平台开发人员工资，服务器租赁，广告费等。'
        },
        {
            question: '怎么收款并确保信息和资金安全？',
            answer: '我们与Stripe合作，Stripe 是一个为在线商家提供支付解决方案的平台，Stripe 遵循严格的安全标准，确保用户的支付安全。AIRYVIBE本身不会保存任何客户的支付信息。'
        },
    ];

    return (
        <Box>
            <Typography fontWeight={700} fontSize={24} variant="body1" >
                常见问题回答
            </Typography>
            {faqs.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <Typography fontWeight={800}>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}

export default FAQComponent;