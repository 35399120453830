import React, { useState, useRef } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { filesToBase64Images, } from './commonUtils';
import { useTranslation } from 'react-i18next';

function UploadWithMyCropper({ source, aspectRatio, callback, width = '100%', disabled = false }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [sourceImage, setSourceImage] = useState(source)
    const sourceFileInput = useRef(null);
    const handleSourceFileInput = async (e) => {
        if (e.target.files.length === 0) {
            // console.log('No file selected.');
            return;
        }
        const files = await filesToBase64Images(Array.from(e.target.files));
        setSourceImage(files[0]);
        setOpen(true);
    }
    return (
        <Box>
            <input
                type="file"
                accept="image/*"
                ref={sourceFileInput}
                style={{ display: 'none' }}
                onChange={handleSourceFileInput}
            />
            <Button
                variant='outlined'
                onClick={() => { sourceFileInput.current.click(); }}
                disabled={disabled}>
                {t('cropper.selectPhoto')}
            </Button>
            <Drawer
                open={open}
                onClose={() => { setOpen(false) }}
                anchor={'bottom'}
            >
                <Box
                    justifyContent={'center'} display={'flex'}>
                    <MyCropper
                        source={sourceImage}
                        aspectRatio={aspectRatio}
                        width={width}
                        callback={async (cropped) => {
                            await callback(cropped);
                            setOpen(false);
                        }}
                        closeHandler={() => { setOpen(false) }}
                    />
                </Box>
            </Drawer>
        </Box>
    )
}

function MyCropper({ source, aspectRatio, callback, width = '100%', closeHandler }) {
    const { t } = useTranslation();
    const [sourceImage, setSourceImage] = useState(source)
    const cropperRef = useRef(null);
    const sourceFileInput = useRef(null);
    const handleSourceFileInput = async (e) => {
        if (e.target.files.length === 0) {
            // console.log('No file selected.');
            return;
        }
        const files = await filesToBase64Images(Array.from(e.target.files));
        setSourceImage(files[0]);
    }
    const onConfirm = async () => {
        await callback(cropperRef.current?.cropper.getCroppedCanvas().toDataURL('image/png'));
    };
    return (
        <Box className='myCropper' width={width}>
            <Cropper
                src={sourceImage}
                style={{ height: 400, width: "100%" }}
                // Cropper.js options
                aspectRatio={aspectRatio}
                viewMode={2}
                guides={false}
                ref={cropperRef}
                zoomable={true}
            />
            <Box marginY={1} display={'flex'} justifyContent={'flex-end'}>

                <input
                    type="file"
                    accept="image/*"
                    ref={sourceFileInput}
                    style={{ display: 'none' }}
                    onChange={handleSourceFileInput}
                />
                <Button
                    sx={{ mx: 1 }}
                    variant='contained'
                    onClick={closeHandler}>
                    {t('cropper.close')}
                </Button>
                <Button
                    sx={{ mx: 1 }}
                    variant='contained'
                    onClick={() => { sourceFileInput.current.click(); }}>
                    {t('cropper.selectPhoto')}

                </Button>
                <Button
                    sx={{ mx: 1 }}
                    variant='contained'
                    onClick={onConfirm}>
                    {t('cropper.confirm')}
                </Button>
            </Box>
            {
                /*
            <Box style={{ maxWidth: '100%' }} justifyContent={'center'} display={'flex'}>
                <img src={cropped} ></img>
            </Box>
                */
            }
        </Box >
    );
}
export { UploadWithMyCropper, MyCropper }