import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
// import HomePage from './components/HomePage/HomePage';
// import MenuPage from './components/MenuPage/MenuPage';
// import LeasePage from './components/LeasePage/LeasePage';
// import LocationPage from './components/LocationPage/LocationPage';
// import CollectionPage from './components/CollectionPage/CollectionPage'
// import TestPaymentPage from './components/TestPaymentPage'
// import TestPage from './components/TestPage'
// import PaymentSuccessPage from './components/PaymentSuccessPage';
import AboutPage from './components/AboutPage/AboutPage';
import "./App.css"
import { ThemeProvider } from '@mui/material/styles';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/Profile/ProfilePage';
import StudioPage from './components/StudioPage';

// Auth
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import ClosePage from './components/ClosePage';
import MyStudioPage from './components/MyStudio/MyStudioPage';
import PlatformHomePage from './components/PlatformHomePage';
import RegistrationPage from './components/RegistrationPage';
import StudioRegisterPage from './components/MyStudio/StudioRegisterPage';
import Footer from './components/Footer';
import Navbar from './components/Navbar/Navbar';
import OrderDetail from './components/OrdersPage/OrderDetail';
import { Box } from '@mui/material';
import airyVibeTheme from './airyVibeTheme';
import Orders from './components/OrdersPage/Orders';
import { SnackbarProvider } from './components/providers/SnackbarProvider';
import { logPageView, logReferral } from './gtag';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get('ref');
    logReferral(ref);
  }, [location]);

  function navFooter(component) {
    return <Box
      component={'header'}
      display="flex"
      flexDirection="column"
      minHeight={'100vh'}>
      <Navbar />
      <Box
        component="main"
        flex="1" // Take as much space as possible
        display="flex"
        flexDirection="column"
      >
        {component}
      </Box>
      <Footer component="footer" />
    </Box>
  }

  return (
    <ThemeProvider theme={airyVibeTheme}>
      <SnackbarProvider>
        <Routes>
          <Route exact path="/" element={navFooter(<PlatformHomePage />)} />
          {/*
          <Route exact path="/chatbot" element={<ChatbotPage />} />
          <Route exact path="/menu" element={<MenuPage />} />
          <Route exact path="/lease" element={<LeasePage />} />
          <Route exact path="/location" element={<LocationPage />} />
          <Route exact path="/collection" element={<CollectionPage />} />
          <Route exact path="/oldHome" element={<HomePage />} />
          <Route path="/dts" element={<TimeTable />} />
          <Route path="/dtsp" element={<TimePage />} />
          <Route exact path="/testPayment" element={<TestPaymentPage />} />
          <Route exact path="/paymentSuccess" element={navFooter(<PaymentSuccessPage />)} />
          */}
          {/* <Route exact path="/test" element={navFooter(<TestPage />)} /> */}
          <Route exact path="/about" element={navFooter(<AboutPage />)} />
          <Route exact path="/login" element={navFooter(<LoginPage />)} />
          <Route exact path="/profile" element={navFooter(<ProfilePage />)} />
          <Route exact path="/mystudio" element={navFooter(<MyStudioPage />)} />
          <Route exact path="/closePage" element={navFooter(<ClosePage />)} />
          <Route path="/at/:studioName" element={navFooter(<StudioPage />)} />
          <Route path="/order/:orderId" element={navFooter(<OrderDetail />)} />
          <Route exact path="/order" element={navFooter(<Orders />)} />

          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/studioRegister" element={<StudioRegisterPage />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;