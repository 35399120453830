import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

// pass imageDialogOpen, imageDialogSrc, and index in as state
function ImgListDialog({ imageDialogOpen, imgDialogHandleClose, imageDialogSrc, index }) {
    const [imageDialogIndex, setimageDialogIndex] = useState(index);
    useEffect(() => {
        setimageDialogIndex(index)
    }, [index])
    return (
        <Dialog
            open={imageDialogOpen}
            onClose={imgDialogHandleClose}
            maxWidth={false}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    width: 'auto',
                    height: 'auto',
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: 0,
                    width: 'auto',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <img
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100vh',
                        objectFit: 'contain',
                    }}
                    src={imageDialogSrc[imageDialogIndex]}
                    alt='zoomin' />
                <Box position={'absolute'} right={0} top={0}
                    onClick={imgDialogHandleClose}
                >
                    <Button color='inherit'>
                        <CloseIcon fontSize='large' />
                    </Button>
                </Box>
                <Box
                    position="absolute"
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={0}
                    bgcolor="rgba(0,0,0,0.7)" // Optional: adds a semi-transparent background
                    zIndex={2}
                >
                    <Button
                        color='inherit'
                        onClick={() => {
                            if (imageDialogIndex === 0) return;
                            setimageDialogIndex(imageDialogIndex - 1);
                        }}
                    >
                        <ArrowBackIosOutlinedIcon fontSize='large' sx={{ color: 'white' }} />
                    </Button>
                    <Box>
                        <Typography variant="body1" color="white"> {/* Optional: set text color */}
                            {imageDialogIndex + 1} / {imageDialogSrc.length}
                        </Typography>
                    </Box>
                    <Button
                        color='inherit'
                        onClick={() => {
                            if (imageDialogIndex === imageDialogSrc.length - 1) return;
                            setimageDialogIndex(imageDialogIndex + 1);
                        }}
                    >
                        <ArrowForwardIosIcon fontSize='large' sx={{ color: 'white' }} />
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ImgListDialog;