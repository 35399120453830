import { getUser } from "../User";

function assignUserID() {
    // Generate a unique session ID (you can use a library like UUID to generate a unique ID)
    var userID = Math.floor(Math.random() * 100000000000);
    // Store the session ID in the session storage or local storage
    localStorage.setItem('userID', userID);
    return userID;
}

// Retrieving the session ID for the user
async function getUserID() {
    try {
        const user = await getUser()
        let userID = user.username;
        if (userID) return userID;
    } catch (e) {
        console.info('user is not login')
    }
    let userID = localStorage.getItem('userID');
    if (!userID) {
        userID = assignUserID();
    }
    return 'unregistered' + userID;
}

export { assignUserID, getUserID };