import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ImgDialog({ src, open, handleClose }) {

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                repositionOnUpdate={false}
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                }}
                aria-labelledby="image-dialog-title"
                aria-describedby="image-dialog-description"
            >
                <DialogContent style={{
                    padding: 0,
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img
                        src={src}
                        alt='zoomin'
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100vh',
                            objectFit: 'contain',
                        }}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}