import React, { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import { getUserData } from '../User';
import { getStudioBookedTime } from '../apis';
import { useTranslation } from 'react-i18next';

// note: this component display all the booked time and users can use it as a
// calendar event management tool

// UI plan:
// 1 pink button on top left for adding event.
// 2 buttons on top right for export and import
// A list of events chronologically sorted from selected week start time

// future, interactive UI

// TODO: read all orders and generate time - order mapping. (blocked by time in order is not implemented)
// TODO: read all calendar data from user and transform to time - event mapping
// ...

function MyStudioTimeBooked({ user }) {
    const { t } = useTranslation();
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    let requested = false;

    const [timeData, setTimeData] = useState([]);
    function handleAddEvent() {
        console.error("still in dev");
    }
    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    const [studioBookedTime, setStudioBookedTime] = useState(null);

    async function updateUserData(originalUserData, updatedUserData) {
        if (!user) {
            console.error('did not login yet')
            return;
        }
        const requestBody = {
            path: 'updateUserData', accessToken: user.signInUserSession.accessToken.jwtToken,
            originalUserData: originalUserData, updatedUserData: updatedUserData
        };
        let apiResponse = undefined;
        await fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/userAuth',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(requestBody),
            }).then(function (response) {
                console.log(response);
                return response.text();
            }).then(response => {
                apiResponse = response;
                console.log(apiResponse);
            })
            .catch(error => {
                console.error(error);
            });
        return apiResponse;
    }
    function hasEmptyString(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const responseObject = await getUserData(user);
            if (responseObject === '{') {
                showErrorDialog(411, "Token expired.\nPlease refresh to continue.");
                return;
            }
            setUserData(responseObject);
            if ('timeData' in responseObject) {
                console.log('timeData exists');
                setTimeData(responseObject.timeData);
            } else {
                console.log('not found')
            }
            if ('studioName' in responseObject) {
                console.log('studioName exists');
                const studioBookedTime = await getStudioBookedTime(responseObject['studioName'])
                setStudioBookedTime(studioBookedTime);
            } else {
                console.log('not found')
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (
        <Box id={'MyStudioTimeBooked'}>
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                    {t('constant.comingSoon')}
                    <Button
                        variant="contained"
                        color="pink"
                        onClick={handleAddEvent}
                        sx={{ width: 200, mb: 2 }}
                    >
                        Add Event
                    </Button>
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyStudioTimeBooked;