import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Button, Box, Grid, Link, Typography } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getUserAndData } from '../User';
import { useNavigate } from 'react-router-dom';
import ProfileStripe from './ProfileStripe';
import ProfileBasicInfo from './ProfileBasicInfo';
import StudioNameForm from './StudioNameForm';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

function ProfilePage() {
    const navigate = useNavigate();
    const requestedRef = useRef(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState(undefined);
    const [contentSelected, setContentSelected] = useState('basicInfo');
    const { t } = useTranslation();

    useEffect(() => {
        if (requestedRef.current) return;
        requestedRef.current = true;

        async function loadUser() {
            const userAndData = await getUserAndData();
            if (userAndData.user === null) {
                console.warn("Load user failed.\nMaybe you are not logged in yet.\nRedirecting to login page in 3 seconds.");
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
            if (userAndData.userData === null) {
                console.warn("Load user data failed.");
                return;
            }
            setUserType(userAndData.userData.userType);
            setUser(userAndData.user);
        }
        loadUser();
    }, [navigate]);
    useEffect(() => {
        if (!user) return;
        if (userType === undefined) {
            console.log("this user does not have userType set, redirect to registration.");
            const timer = setTimeout(() => {
                navigate('/registration');
            }, 1000);
            return () => clearTimeout(timer); // Cleanup
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function handleTitleClick(contentName) {
        if (contentSelected === contentName) {
            return;
        }
        setContentSelected(contentName);
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Airy Vibe
        </Link>,
        <Typography key="3" color="text.primary">
            Profile
        </Typography>,
    ];
    const buttons = [
        {
            title: t('profile.basicInfo'),
            audience: 'all',
            onClick: () => { handleTitleClick('basicInfo') }
        },
        {
            title: t('profile.studioInfo'),
            audience: 'photographer',
            onClick: () => { handleTitleClick('studioName') }
        },
        {
            title: t('profile.paymentAccount'),
            audience: 'photographer',
            onClick: () => { handleTitleClick('Stripe') }
        }
    ];
    return (
        <Box className='ProfilePage'>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Box id='contentBox-photographer'>
                <Grid container>
                    <Grid item xs={2}>
                        <Box sx={{ backgroundColor: "#eeeeee", padding: { xs: "3px", md: 2 } }}>
                            {buttons.map((button) => (
                                (button.audience === 'all' || button.audience === userType) && (
                                    <Button
                                        fullWidth
                                        size='small'
                                        sx={{
                                            textAlign: 'left',
                                            fontSize: { xs: '12px', md: '14px' },
                                            display: "flex !important",
                                            justifyContent: "left !important",
                                            color: '#000000'
                                        }}
                                        key={button.title}
                                        onClick={button.onClick}
                                    >
                                        {button.title}
                                    </Button>
                                )
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        {contentSelected === 'basicInfo' && <Box p={1}><ProfileBasicInfo /></Box>}
                        {contentSelected === 'studioName' && <StudioNameForm user={user} />}
                        {contentSelected === 'Stripe' && <ProfileStripe user={user} />}
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
export default withAuthenticator(ProfilePage);
