
const dayMap = {
    "Sunday": 0, "Monday": 1, "Tuesday": 2, "Wednesday": 3,
    "Thursday": 4, "Friday": 5, "Saturday": 6
};
const time48 = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "24:00"]
/**
 * Converts an array of time slot objects into a 24x7 2D boolean array.
 * Each slot in the array represents one hour of a week, where `true` means
 * the time slot is covered and `false` means it is not.
 *
 * @param {Object[]} timeData - Array of time slot objects with day, start, and end times.
 * @returns {boolean[][]} A 24x7 2D boolean array representing time slots in a week.
 *
 * @example
 * const schedule = [
 *   { day: "Thursday", start: "12:00", end: "18:00" },
 *   // ... other time slots ...
 * ];
 * const timeSlots = createTimeSlotsArray(schedule);
 * console.log(timeSlots); // 24x7 2D boolean array
 */
function transformTimeDataTo724(timeData) {
    // Initialize a 24x7 array filled with `false`
    const slots = Array.from({ length: 24 }, () => Array(7).fill(false));

    // Map for converting days to their respective indices

    // Helper function to parse hour from time
    const getHour = (time) => parseInt(time.split(':')[0], 10);

    // Fill the slots based on the time data
    timeData.forEach(item => {
        const dayIndex = dayMap[item.day];
        const startHour = getHour(item.start);
        const endHour = getHour(item.end);

        for (let hour = startHour; hour < endHour; hour++) {
            slots[hour][dayIndex] = true; // Set the slot to `true`
        }
    });

    return slots;
}
/**
 * Converts a 24x7 2D boolean array representing time slots in a week back into
 * an array of time slot objects with day, start, and end times.
 *
 * @param {boolean[][]} slots - A 24x7 2D boolean array of time slots.
 * @returns {Object[]} An array of time slot objects with day, start, and end times.
 *
 * @example
 * const slots = [
 *   // ... 24x7 2D boolean array ...
 * ];
 * const timeData = reverseCreateTimeSlotsArray(slots);
 * console.log(timeData); // [{ day: "Thursday", start: "12:00", end: "18:00" }, ...]
 */
function transform724ToTimeData(slots) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let timeData = [];

    slots.forEach((hours, hourIndex) => {
        hours.forEach((slot, dayIndex) => {
            // If the slot is true and either it's the first hour or the previous hour was false
            if (slot && (hourIndex === 0 || !slots[hourIndex - 1][dayIndex])) {
                // Find the end time by looking ahead in the hours
                let endHourIndex = hourIndex;
                while (endHourIndex < 24 && slots[endHourIndex][dayIndex]) {
                    endHourIndex++;
                }

                timeData.push({
                    day: days[dayIndex],
                    start: `${hourIndex < 10 ? '0' + hourIndex : hourIndex}:00`,
                    end: `${endHourIndex < 10 ? '0' + endHourIndex : endHourIndex}:00`
                });
            }
        });
    });
    timeData = timeData.sort((a, b) => dayMap[a.day] - dayMap[b.day]);
    console.log(timeData)
    return timeData;

}

/**
 * Creates a 48x7 2D boolean array representing half-hour time slots for each day of the week.
 * Each day has 48 half-hour slots starting from 00:00 to 24:00.
 * The input is an array of objects where each object specifies the day, start time, and end time.
 *
 * @param {Object[]} schedule - The schedule data containing days and start/end times.
 * @param {string} schedule[].day - The day of the week ("Monday", "Tuesday", etc.).
 * @param {string} schedule[].start - The start time in 24-hour format ("HH:MM").
 * @param {string} schedule[].end - The end time in 24-hour format ("HH:MM").
 * @returns {boolean[][]} A 48x7 2D boolean array representing the filled time slots.
 */
function transformTimeDataTo748(schedule) {
    // Initialize a 48x7 2D array filled with false
    const timeSlots = Array.from({ length: 48 }, () => Array(7).fill(false));
    // Helper function to get the index for the day
    // Helper function to convert time to a 30-minute slot index
    const getTimeIndex = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 2 + (minutes >= 30 ? 1 : 0);
    };
    // Populate the timeSlots array based on the schedule
    schedule.forEach(({ day, start, end }) => {
        const dayIndex = dayMap[day];
        const startIndex = getTimeIndex(start);
        const endIndex = getTimeIndex(end);

        for (let i = startIndex; i < endIndex; i++) {
            timeSlots[i][dayIndex] = true;
        }
    });
    console.log(1);
    console.log(JSON.stringify(timeSlots));
    return timeSlots;
}
/**
 * Converts a 48x7 2D boolean array representing half-hour time slots for each day of the week
 * into an array of objects with day, start, and end times.
 *
 * @param {boolean[][]} slots - A 48x7 2D boolean array representing the filled time slots.
 * @returns {Object[]} The schedule data containing days and start/end times.
 */
function transform748ToTimeData(slots) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let timeData = [];

    slots.forEach((hours, hourIndex) => {
        hours.forEach((slot, dayIndex) => {
            // If the slot is true and either it's the first hour or the previous hour was false
            if (slot && (hourIndex === 0 || !slots[hourIndex - 1][dayIndex])) {
                // Find the end time by looking ahead in the hours
                let endHourIndex = hourIndex;
                while (endHourIndex < 48 && slots[endHourIndex][dayIndex]) {
                    endHourIndex++;
                }

                timeData.push({
                    day: days[dayIndex],
                    start: time48[hourIndex],
                    end: time48[endHourIndex]
                });
            }
        });
    });
    timeData = timeData.sort((a, b) => dayMap[a.day] - dayMap[b.day]);
    console.log(2);
    console.log(JSON.stringify(timeData));
    return timeData;
}




export {
    transformTimeDataTo724,
    transform724ToTimeData,
    transformTimeDataTo748,
    transform748ToTimeData
}