import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { getUserAndData } from './User';
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CheckoutButton(
    { priceIds, quantities, label, studioName, time_reserve_is_required, time_reserved_start, time_reserved_end, orderNote }
) {
    const { t } = useTranslation();
    const NEED_USER_LOGIN_ERROR_MESSAGE = "Login is required to checkout!";
    const CUSTOMER_ACCOUNT_ONLY_ERROR_MESSAGE = "A Customer Account user is required to checkout! (Photographer account cannot)";
    const USER_DATA_FETCH_FAILED_ERROR_MESSAGE = "Load user data failed.";

    // snackbar for copy
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    const [buttonLock, setButtonLock] = useState(false);
    async function handleCheckout() {
        // Must have Customer's userName and studio name to checkout,
        //   so that we can track that in order

        const userAndData = await getUserAndData();
        if (userAndData.user === null) {
            handleClickSnackbar();
            console.error(NEED_USER_LOGIN_ERROR_MESSAGE);
            setSnackbarErrorMessage(NEED_USER_LOGIN_ERROR_MESSAGE);
            setButtonLock(false);
            return;
        }
        if (userAndData.userData === null) {
            handleClickSnackbar();
            console.error(USER_DATA_FETCH_FAILED_ERROR_MESSAGE);
            setSnackbarErrorMessage(USER_DATA_FETCH_FAILED_ERROR_MESSAGE);
            setButtonLock(false);
            return;
        }
        if (userAndData.userData.userType !== 'customer') {
            handleClickSnackbar();
            console.error(CUSTOMER_ACCOUNT_ONLY_ERROR_MESSAGE);
            setSnackbarErrorMessage(CUSTOMER_ACCOUNT_ONLY_ERROR_MESSAGE);
            setButtonLock(false);
            return;
        }
        const requestBody = {
            path: 'testAug13',
            studioName: studioName,
            orderNote: orderNote,
            customerUserName: userAndData.userData.Username,
            time_reserve_is_required: time_reserve_is_required === true,
            time_reserved_start: time_reserved_start !== null ? time_reserved_start.format('YYYY-MM-DDTHH:mm:ss') : null,
            time_reserved_end: time_reserved_end !== null ? time_reserved_end.format('YYYY-MM-DDTHH:mm:ss') : null
        };
        if (priceIds && quantities) {
            requestBody.priceIds = priceIds;
            requestBody.quantities = quantities;
        }
        // console.log(requestBody);
        await fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/makeorder',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
            }).then(function (response) {
                return response.text();
            }).then(response => {
                let res = JSON.parse(response);
                if (res.statusCode === 303) {
                    const redirectUrl = res.headers.Location;
                    window.location.href = redirectUrl;
                } else {
                    console.log(response);
                    console.error('unhandled request');
                }
                setButtonLock(false);
            })
            .catch(error => {
                console.error(error);
                setButtonLock(false);
            });
    }
    return (
        <>
            <Button
                variant='contained'
                color={'pink'}
                fullWidth
                sx={{ borderRadius: '10px', marginX: '32px' }}
                disabled={buttonLock || (time_reserve_is_required && time_reserved_start === null)}
                onClick={async () => { setButtonLock(true); handleCheckout(); }}>
                {label ? label : t('constant.checkout')}
            </Button>
            <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {snackbarErrorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}