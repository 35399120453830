import React, { useEffect, useState } from 'react';

import { Button, Box, Divider, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { UploadWithMyCropper } from '../MyCropper';
import SimpleDialog from '../SimpleDialog';
import { addRandomCharactersToFileName, base64ToFile } from '../commonUtils';
import { getUserAndData } from '../User';
import { updateUserDataByColumn } from '../apis';
import { useSnackbar } from '../providers/SnackbarProvider';
import { StudioCard } from '../PlatformHomePage';
import { useTranslation } from 'react-i18next';
function MyLogoAndCoverForm({ user, submitCallback, showStatus, maxWidth }) {
    const { t } = useTranslation();
    const callSnackbar = useSnackbar();
    if (maxWidth === undefined) {
        maxWidth = '100%';
    }
    const [statusLogoUploaded, setStatusLogoUploaded] = useState(false);
    const [statusCoverUploaded, setStatusCoverUploaded] = useState(false);

    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentArray, setDialogContentArray] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    // buttonLock
    const [isLocked, setIsLocked] = useState(false);

    const [logoFile, setLogoFile] = useState(null);
    const [logoFileName, setLogoFileName] = useState('');
    const [coverFile, setCoverFile] = useState(null);
    const [coverFileName, setCoverFileName] = useState('');
    let useEffectCalled = false;

    useEffect(() => {
        setStatusLogoUploaded(userData?.logo !== undefined);
        setStatusCoverUploaded(userData?.cover !== undefined);
        console.log(userData?.cover);
        console.log(userData?.logo);
    }, [userData]);
    async function handleLogoSubmit(e, column) {
        if (column !== 'logo' && column !== 'cover') return;
        if ((column === 'logo' && !logoFile && !logoFileName) ||
            (column === 'cover' && !coverFile && !coverFileName)) {
            showErrorDialog(401, `Please select a ${column} file before submit`);
            return;
        }
        e.preventDefault();
        let columnData = null;
        let file = null;
        if (column === 'logo') {
            if (logoFile.startsWith('https://')) {
                callSnackbar('Please select a Logo file before submit', 3000, 'error');
                return;
            }
            file = base64ToFile(logoFile);
            columnData = { fileName: logoFileName, fileType: file.type };
        }
        else if (column === 'cover') {
            if (coverFile.startsWith('https://')) {
                callSnackbar('Please select a Cover file before submit', 3000, 'error');
                return;
            }
            file = base64ToFile(coverFile);
            columnData = { fileName: coverFileName, fileType: file.type };
        }
        console.log(file);
        console.log(columnData);
        const apiResponse = JSON.parse(await updateUserDataByColumn(user, columnData, column));
        if (apiResponse['statusCode'] === 200) {
            // lets not refresh for now window.location.reload();
            console.log(apiResponse);
            const uploadUrl = apiResponse['nextStep']['uploadUrl']
            const options = {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            };
            const response = await fetch(uploadUrl, options);
            if (response.ok) {
                console.log('Successfully uploaded file.');
                if (submitCallback) {
                    submitCallback();
                }
                callSnackbar('Successfully uploaded Logo.', 3000, 'success');
                return true;
            } else {
                console.log('Upload failed:', response.status, response.statusText);
                showErrorDialog(apiResponse['statusCode'], response.statusText)
                return false;
            }
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            console.log(apiResponse['body']);
            let errorMessage = apiResponse['body']['error'];
            if (typeof apiResponse['body'] === 'string')
                errorMessage = JSON.parse(apiResponse['body'])['error']
            showErrorDialog(apiResponse['statusCode'], errorMessage)
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (useEffectCalled) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        else useEffectCalled = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('logo' in userAndData.userData) {
                console.log('logo found');
                setLogoFile(userAndData.userData['logo'])
            } else {
                console.log('logo NOT found')
            }
            if ('cover' in userAndData.userData) {
                console.log('cover found');
                setCoverFile(userAndData.userData['cover'])
            } else {
                console.log('cover NOT found')
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }




    return (
        <Box id={'MyLogoAndCoverForm'} >
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                    <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>

                        <Box >
                            <Box>
                                {logoFile ? <img src={logoFile} width={200} maxWidth={200} alt='logofileuploaded'></img> : <p>You don't have a logo yet</p>}
                            </Box>
                            <Box width={360} maxWidth={'100%'} display={'flex'}>
                                <UploadWithMyCropper
                                    source={"https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"}
                                    aspectRatio={2 / 2}
                                    width='80%'
                                    callback={(cropped) => {
                                        const fileNames = addRandomCharactersToFileName('logo.png', 30);
                                        setLogoFile(cropped);
                                        setLogoFileName(fileNames);;
                                    }}
                                />
                                <Button
                                    sx={{ marginX: 1 }}
                                    variant="contained"
                                    color="pink"
                                    disabled={isLocked}
                                    onClick={async (event) => {
                                        setIsLocked(true);
                                        await handleLogoSubmit(event, 'logo');
                                        setIsLocked(false);
                                    }}
                                >
                                    {t('mystudio.uploadLogo')}
                                </Button>
                                {showStatus && <Typography>{statusLogoUploaded ? '✅' : `${t('constant.upload')}:❌`}</Typography>}
                            </Box>
                        </Box>
                        <Divider></Divider>
                        <Box >
                            <Box >
                                {coverFile ? <img src={coverFile} width={300} maxWidth={300} alt='coverfileuploaded'></img> : <p> You don't have a cover yet</p>}
                            </Box>
                            <Box width={360} maxWidth={'100%'} display={'flex'}>
                                <UploadWithMyCropper
                                    source={"https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"}
                                    aspectRatio={3 / 2}
                                    width='100%'
                                    callback={(cropped) => {
                                        const fileNames = addRandomCharactersToFileName('cover.png', 30);
                                        setCoverFile(cropped);
                                        setCoverFileName(fileNames);;
                                    }}
                                />
                                <Button
                                    sx={{ marginX: 1 }}
                                    variant="contained"
                                    color="pink"
                                    disabled={isLocked}
                                    onClick={async (event) => {
                                        setIsLocked(true);
                                        await handleLogoSubmit(event, 'cover');
                                        setIsLocked(false);
                                    }}
                                >
                                    {t('mystudio.uploadCover')}
                                </Button>
                                {showStatus && <Typography>{statusCoverUploaded ? '✅' : `${t('constant.upload')}:❌`}</Typography>}
                            </Box>
                        </Box>
                        <Box border={'1px solid rgba(0,0,0,0.1)'}>
                            <Typography>{t('constant.preview')}</Typography>
                            <StudioCard studio={{ ...userData, logo: logoFile, cover: coverFile }} visitStudioHandler={() => { }} />
                        </Box>
                    </Box>
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyLogoAndCoverForm;