import React from 'react';

import { Stack, Typography } from '@mui/material';

export default function ClosePage() {
    // 3 seconds
    React.useEffect(() => {
        const timer = setTimeout(() => {
            window.close();
        }, 3000); // Close the page after 3 seconds

        return () => clearTimeout(timer); // Cleanup
    }, []);

    return (
        <div className='TestPaymentPage'>
            <Stack sx={{ px: 1 }} className='pageContent'>
                <Typography sx={{ mt: 3 }} fontSize={{ xs: 30, sm: 60, lg: 80 }} fontFamily={"CustomFont"} align='center'>
                    Glory Universe Photography Studio LA
                </Typography>
                <Typography fontSize={{ xs: 16, md: 20, lg: 24 }}>
                    You may close this page now!
                </Typography>
            </Stack>
        </div>
    );
}