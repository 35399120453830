import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function BlockingDialog({ title, open, onClose, child, showDismissButton }) {
    return (
        <div className="BlockingDialog">
            <Dialog
                open={open}
                onClose={() => { }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {child}
                </DialogContent>
                <DialogActions>
                    {showDismissButton &&
                        <Button variant={'contained'} onClick={onClose} color="primary">
                            Dismiss
                        </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
