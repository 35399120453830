import React, { useEffect, useState } from 'react';

import { Button, Box, TextField, Grid } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import { getUserAndData } from '../User';
import { updateUserData } from '../apis';
import { useSnackbar } from '../providers/SnackbarProvider';

function MyStudioInfoForm({ user, submitCallback }) {
    const callSnackbar = useSnackbar();

    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressZip, setAddressZip] = useState('');

    const zipRegex = /^[0-9]{5}$/;
    const stateRegex = /^[A-Z]{2}$/;
    const [errors, setErrors] = useState({
        address1: false,
        addressCity: false,
        addressState: false,
        addressZip: false,
    });
    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    function loadForm(form) {
        setAddress1(form.address1);
        setAddress2(form.address2);
        setAddressCity(form.addressCity);
        setAddressState(form.addressState);
        setAddressZip(form.addressZip);
    }
    let requested = false;
    async function handleSubmit(e) {
        e.preventDefault();
        // Validate required fields
        if (!address1 || !addressCity || !stateRegex.test(addressState) || !zipRegex.test(addressZip)) {
            setErrors({
                address1: !address1,
                addressCity: !addressCity,
                addressState: !addressState || !stateRegex.test(addressState),
                addressZip: !addressZip || !zipRegex.test(addressZip),
            });
            return;
        }
        const formData = {
            address1,
            address2,
            addressCity,
            addressState,
            addressZip,
        };
        console.log(formData);
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['basicInfo'] = JSON.parse(JSON.stringify(formData));
        const apiResponse = JSON.parse(await updateUserData(user, userData, updatedUserData));
        console.log(apiResponse);
        console.log(apiResponse['statusCode']);
        console.log(apiResponse['statusCode'] === 200);
        if (apiResponse['statusCode'] === 200) {
            callSnackbar('Successfully updated studio information.', 3000, 'success');
            if (submitCallback) {
                submitCallback();
            }
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['error'])
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('basicInfo' in userAndData.userData) {
                console.log('basicInfo exists');
                loadForm(userAndData.userData.basicInfo);
            } else {
                console.error('basicInfo not found')
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        if (!errorMessage) errorMessage = "";
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (<Box id={'MyStudioInfoForm'}>
        <SimpleDialog
            id="simple-dialog"
            open={dialogOpen}
            title={dialogTitle}
            content={dialogContentArray}
            onClose={() => { setDialogOpen(false) }}
        ></SimpleDialog>
        {init ? (
            <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                    <TextField
                        label="Address line 1"
                        value={address1}
                        error={errors.address1}
                        onChange={(e) => setAddress1(e.target.value)}
                        variant="outlined"
                        required />
                    <TextField
                        label="Address line 2"
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                        variant="outlined" />
                    <TextField
                        label="City"
                        value={addressCity}
                        error={errors.addressCity}
                        onChange={(e) => setAddressCity(e.target.value)}
                        variant="outlined"
                        required />
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="State"
                                value={addressState}
                                error={errors.addressState}
                                onChange={(e) => setAddressState(e.target.value.toUpperCase())}
                                inputProps={{ maxLength: 2 }}
                                placeholder="CA, NY ..."
                                helperText={errors.addressState ? "Enter 2 capital letters (e.g., CA, NY)" : ""}
                                variant="outlined"
                                required
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ paddingLeft: { md: 1 }, marginTop: { xs: 2, md: 0 } }}>
                            <TextField
                                label="Zip Code"
                                value={addressZip}
                                error={errors.addressZip}
                                onChange={(e) => setAddressZip(e.target.value)}
                                variant="outlined"
                                required
                                fullWidth />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>)
            :
            (<>Loading ...</>)
        }
    </Box>
    );
}
export default MyStudioInfoForm;