import React, { useEffect, useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, Drawer, TextField, Rating, Divider, Collapse, IconButton, Avatar, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserAndData } from '../User';
import { getOrder, updateOrder, submitReview, orderImageAction } from '../apis';
import sendButton from '../img/sendButton.svg'
import { formatImages, uploadFileToUrl, downloadBlob, getUserDisplayName } from '../commonUtils';


import Breadcrumbs from '@mui/material/Breadcrumbs';
import { StyledBreadcrumb } from '../BreadCrumb/BreadCrumb';
import CopyTypography from '../../widgets/CopyTypography/CopyTypography';
import logoWithText from '../img/logoWithText.svg'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../providers/SnackbarProvider';
import airyVibeTheme, { pinkStyle, pink, white, grey, blue, black } from '../../airyVibeTheme';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import UserAvatar from '../UserAvatar';
const imageStates = ['photo', 'pendingEdit', 'editedPhoto'];

function ReviewDrawer({ onSubmit, initialReviewData, order }) {
    const callSnackbar = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [qualityRating, setQualityRating] = useState(initialReviewData.qualityRating);
    const [guidingRating, setGuidingRating] = useState(initialReviewData.guidingRating);
    const [communicationRating, setCommunicationRating] = useState(initialReviewData.communicationRating);
    const [review, setReview] = useState(initialReviewData.review);
    const [selectedImages, setSelectedImages] = useState([]);
    const { t } = useTranslation();

    const isFormValid = qualityRating && guidingRating && communicationRating && review;

    const handleSubmit = async () => {
        try {
            await onSubmit({ qualityRating, guidingRating, communicationRating, review, selectedImages });
            setIsOpen(false);
            callSnackbar('Review submitted successfully', 3000, 'success');
        } catch (error) {
            console.error('Error submitting review:', error);
            const errorMessage = error.message || 'An error occurred while submitting the review';
            callSnackbar('Error submitting review: ' + errorMessage, 3000, 'error');
        }
    };

    const handleReviewChange = (e) => {
        const input = e.target.value;
        if (input.length <= 600) {
            setReview(input);
        }
    };

    const handleImageSelect = (imageId) => {
        setSelectedImages(prev => {
            if (prev.includes(imageId)) {
                return prev.filter(id => id !== imageId);
            } else if (prev.length < 20) {
                return [...prev, imageId];
            }
            return prev;
        });
    };

    // Add this new function to get the selection status message
    const getSelectionStatusMessage = () => {
        const count = selectedImages.length;
        if (count === 0) {
            return t('order.noImagesSelected');
        } else if (count === 20) {
            return t('order.maxImagesSelected');
        } else {
            return t('order.imagesSelectedCount', { count, max: 20 });
        }
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setIsOpen(true)}
                sx={{ mt: 2 }}
            >
                {t('order.writeReview')}
            </Button>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box sx={{ p: 2, width: '100%', maxWidth: 500, margin: '0 auto' }}>
                    <Typography variant="h6" gutterBottom>
                        {t('order.writeYourReview')}
                    </Typography>
                    <Typography fontSize={14}>{t('studio.ratingCategory.final_quality')}</Typography>
                    <Rating
                        value={qualityRating}
                        onChange={(event, newValue) => setQualityRating(newValue)}
                        sx={{ mb: 1, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <Typography fontSize={14}>{t('studio.ratingCategory.guiding')}</Typography>
                    <Rating
                        value={guidingRating}
                        onChange={(event, newValue) => setGuidingRating(newValue)}
                        sx={{ mb: 1, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <Typography fontSize={14}>{t('studio.ratingCategory.communication')}</Typography>
                    <Rating
                        value={communicationRating}
                        onChange={(event, newValue) => setCommunicationRating(newValue)}
                        sx={{ mb: 2, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <TextField
                        multiline
                        rows={5}
                        variant="outlined"
                        label={t('order.writeYourReview')}
                        value={review}
                        onChange={handleReviewChange}
                        inputProps={{ maxLength: 600 }}
                        helperText={`${review.length}/600`}
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="h6" gutterBottom>
                        {t('order.selectImages')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {order.images.map((image) => (
                            <Box
                                key={image.imageId}
                                sx={{
                                    position: 'relative',
                                    width: 100,
                                    height: 100,
                                    cursor: 'pointer',
                                    border: selectedImages.includes(image.imageId) ? '2px solid #FF9696' : '2px solid transparent',
                                }}
                                onClick={() => handleImageSelect(image.imageId)}
                            >
                                <img
                                    src={image.thumbnailSignedUrl || image.imageSignedUrl}
                                    alt={`pic-${image.imageId}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                {selectedImages.includes(image.imageId) && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            bgcolor: '#FF9696',
                                            borderRadius: '50%',
                                            width: 20,
                                            height: 20,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ color: 'white', fontWeight: 'bold' }}>&#10003;</Typography>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Box>
                        <Typography variant="caption" fontSize={10}>{getSelectionStatusMessage()}</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!isFormValid}
                        sx={{ mt: 2 }}
                    >
                        {t('order.submitReview')}
                    </Button>
                    {!isFormValid && (
                        <Typography color="error" sx={{ mt: 1, fontSize: '0.875rem' }}>
                            {t('order.reviewFormIncomplete')}
                        </Typography>
                    )}
                </Box>
            </Drawer>
        </>
    );
}

function OrderDetail() {
    let params = useParams();
    const orderId = params.orderId;
    const navigate = useNavigate();
    const callSnackbar = useSnackbar();
    const [orderData, setOrderData] = useState();
    const [threads, setThreads] = useState([]);
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [imageIndex, setImageIndex] = useState({});
    const [threadIndex, setThreadIndex] = useState({});
    const [isImageQualityOriginal, setImageQualityOriginal] = useState(true);
    const location = useLocation();
    let requested = false;
    const { t } = useTranslation();

    const getDelayFromURL = () => {
        const searchParams = new URLSearchParams(location.search);
        const delay = searchParams.get('delay');
        return delay ? parseInt(delay, 10) : 0;
    };

    const [selectedList, setSelectedList] = useState(null);
    const [openThreadId, setOpenThreadId] = useState(null);
    const [showUploader, setShowUploader] = useState(false);

    // Handles list click, updating URL and showing the list
    const handleListClick = (listType) => {
        setSelectedList(listType);
        setOpenThreadId(null); // Close any open thread
        navigate(`/order/${orderId}?list=${listType}`, { replace: true });
    };

    // Closes the list and removes the list and threadId from the URL
    const handleListClose = () => {
        setSelectedList(null);
        setOpenThreadId(null); // Close any open thread as well
        navigate(`/order/${orderId}`, { replace: true }); // Reset to base URL without list and thread
    };

    // Handles thread click within a list, updating URL and opening dialog
    const handleThreadClick = (threadId) => {
        setOpenThreadId(threadId);
        navigate(`/order/${orderId}?list=${selectedList}&threadId=${threadId}`, { replace: true });
    };

    // Closes the thread dialog and removes the threadId from the URL
    const handleThreadClose = () => {
        setOpenThreadId(null);
        navigate(`/order/${orderId}?list=${selectedList}`, { replace: true });
    };

    const handleUploadClick = () => {
        setShowUploader(true);
    };

    const handleUploaderClose = () => {
        setShowUploader(false);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const list = params.get("list");
        const threadId = params.get("threadId");
        if (list) {
            setSelectedList(list);
        }
        if (threadId) {
            setOpenThreadId(threadId);
        }
    }, [location.search]);


    useEffect(() => {
        if (requested) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        requested = true;
        async function loadOrder() {
            const { user, userData } = await getUserAndData();
            setUser(user);
            setUserData(userData);
            if (user === null) {
                console.warn("Load user failed.\nMaybe you are not login yet.\nRedirect to login page in 5 second.");
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
            const delay = getDelayFromURL();
            if (delay > 0) {
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
            }
            const response = await getOrder(user, orderId, true);
            if (!('images' in response.body)) {
                response.body.images = []
            }
            setOrderData(response.body);
            setThreads(response?.body?.orderImageThreads ?? []);
            let imageIdToIndex = {};
            (response.body.images ?? []).forEach((image, index) => {
                imageIdToIndex[image.imageId] = index;
            })
            setImageIndex(imageIdToIndex);
            let threadIdToIndex = {};
            (response.body.orderImageThreads ?? []).forEach((thread, index) => {
                threadIdToIndex[thread.threadId] = index;
            })
            setThreadIndex(threadIdToIndex);
        }
        loadOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function getImageById(imageId) {
        return orderData?.images?.[imageIndex[imageId]];
    }

    function getThreadById(threadId) {
        return orderData?.orderImageThreads?.[threadIndex[threadId]];
    }

    const [reviewData, setReviewData] = useState({
        qualityRating: 0,
        guidingRating: 0,
        communicationRating: 0,
        review: ''
    });

    const handleReviewSubmit = async (reviewData) => {
        try {
            const response = await submitReview(user, orderId, reviewData);
            if (response.statusCode === 200) {
                // Update the local state with the submitted data
                setReviewData(reviewData);
                // Optionally, update the order data if the API returns updated order information
                const newOrderData = { ...orderData, review: response.order?.review };
                console.log('newOrderData', newOrderData);
                setOrderData(newOrderData);
                // TODO: Show a success message to the user
            } else {
                throw new Error(response.message || response.errorMessage || response.error || 'Failed to submit review');
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            throw error; // Re-throw the error to be caught in the ReviewDrawer
        }
    };

    return (
        <Box className='OrderDetail'
            sx={{ bgcolor: `${airyVibeTheme.palette.background.main}` }}
            display="flex"
            flexDirection="column"
            flex="1" // Makes the OrderDetailPage grow
        >
            {(selectedList && openThreadId) && (
                <ThreadDetail handleThreadClose={handleThreadClose} />

            )}
            {(selectedList && !openThreadId) && (
                <ThreadList handleListClose={handleListClose} />
            )}
            {!selectedList && !showUploader && (
                <Box>
                    <Box my={1} mx={1}>
                        <CustomizedBreadcrumbs />
                    </Box>
                    <Box
                        sx={{
                            mx: 1,
                            mb: 1,
                            py: 2,
                            px: 2,
                            bgcolor: `${white}`,
                            borderRadius: '12px',
                            border: `2px solid ${pink[2]}`,
                            maxWidth: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        {orderData?.status && (orderData.status !== 'unpaid' || orderData.status !== 'cancelled') && <OrderStepper />}
                        <OrderCard
                            order={orderData}
                        />
                        <Divider
                            sx={{
                                my: 1,
                                borderColor: `${pink[1]}`, // Set the color to pink
                                borderStyle: 'dashed', // Set the style to dashed
                            }} />
                        {
                            (user && orderData)
                                ? <OrderAction user={user} userData={userData} order={orderData} setOrderData={setOrderData} />
                                : <p>{t('order.loading_data')}</p>
                        }
                        {
                            (orderData?.status === 'completed' ||
                                orderData?.status === 'photoPicking' ||
                                (orderData?.confirmed_service_by_photographer && userData?.userType === 'photographer')
                            ) &&
                            <OrderImageList handleListClick={handleListClick} handleUploadClick={handleUploadClick} />
                        }
                    </Box>
                </Box>
            )}
            {showUploader && (
                <Uploader handleClose={handleUploaderClose} />
            )}
        </Box >
    );

    async function updateOrderImageThreadsState(thread_ids, newState, message, onSuccess, onFailure) {
        const body = {
            action: 'updateOrderImageThreadsState',
            actionRole: userData.userType,
            orderId: orderData.orderID,
            imageThreadIds: thread_ids,
            state: newState,
            message: message // Add the message to the request body
        }
        let successful = false;
        try {
            const response = await orderImageAction(body);
            const parsedResponse = JSON.parse(response);
            console.log('parsedResponse', parsedResponse);
            if (parsedResponse.statusCode === 200) {
                const newThreads = parsedResponse.body?.orderImageThreads;
                setOrderData({ ...orderData, orderImageThreads: newThreads });
                setThreads(newThreads ?? []);
                successful = true;
            } else {
                throw new Error(parsedResponse.message || 'Failed to update order image threads state');
            }
        } catch (error) {
            console.error('Error updating order image threads state:', error);
            // Handle error (e.g., show an error message to the user)
        } finally {
            if (successful) {
                onSuccess();
            } else {
                onFailure();
            }
        }
    }

    function Uploader({ handleClose }) {
        const [selectedFiles, setSelectedFiles] = useState([]);
        const MAX_FILE_SIZE = 60 * 1024 * 1024; // 60 MB in bytes

        const handleFileSelect = (event) => {
            const newFiles = Array.from(event.target.files)
                .filter(file => file.type.startsWith('image/'))
                .filter(file => file.size <= MAX_FILE_SIZE)
                .map(file => ({
                    file,
                    status: 'To Do',
                    preview: URL.createObjectURL(file)
                }));

            setSelectedFiles(prevFiles => {
                const existingFileNames = new Set(prevFiles.map(f => f.file.name));
                const filesToAdd = newFiles.filter(f => !existingFileNames.has(f.file.name));
                return [...prevFiles, ...filesToAdd];
            });

            // Optionally, you can add a warning for files that were too large
            const oversizedFiles = Array.from(event.target.files)
                .filter(file => file.size > MAX_FILE_SIZE);
            if (oversizedFiles.length > 0) {
                alert(`The following files exceed the 60 MB limit and were not added:\n${oversizedFiles.map(f => f.name).join('\n')}`);
            }
        };

        const handleUpload = async () => {
            const fileToBeUploaded = selectedFiles.filter(image => image.status !== 'uploaded');
            const formattedImages = await formatImages(fileToBeUploaded.map(file => file.file));
            let uploadResponse = null;
            try {
                uploadResponse = await orderImageAction({
                    action: 'uploadNewImages',
                    actionRole: userData.userType,
                    orderId: orderData.orderID,
                    images: formattedImages
                });
                console.log(uploadResponse);
            } catch (error) {
                console.error('Error uploading images:', error);
            }
            try {
                uploadResponse = JSON.parse(uploadResponse);
                if (uploadResponse.statusCode === 200) {
                    // request complete, continue to upload the image itself file to s3
                    const uploadUrlsMap = uploadResponse.updateContent
                        ? uploadResponse.updateContent.reduce((map, image) => {
                            map[image.imageName] = image.uploadUrls;
                            return map;
                        }, {})
                        : {};
                    const updatedFiles = [...selectedFiles];
                    for (let imageIndex = 0; imageIndex < fileToBeUploaded.length; imageIndex++) {
                        const fileType = formattedImages[imageIndex].imageFileType;
                        const uploadUrl = uploadUrlsMap[formattedImages[imageIndex].imageName].imageUrl;
                        let uploadResult = false;
                        try {
                            uploadResult = await uploadFileToUrl(fileToBeUploaded[imageIndex].file, fileType, uploadUrl);
                        } catch (error) {
                            console.error('Error uploading images:', error);
                        }
                        const fileIndex = updatedFiles.findIndex(file => file.file === fileToBeUploaded[imageIndex].file);
                        if (fileIndex !== -1) {
                            updatedFiles[fileIndex].status = uploadResult ? 'uploaded' : 'Failed';
                        }
                    }
                    setSelectedFiles(updatedFiles);
                }
            } catch (error) {
                console.error('Error processing upload response:', error);
            }
        };

        const handleDelete = (index) => {
            setSelectedFiles(prevFiles => {
                const newFiles = [...prevFiles];
                URL.revokeObjectURL(newFiles[index].preview);
                newFiles.splice(index, 1);
                return newFiles;
            });
        };
        useEffect(() => {
            console.log("selectedFiles", selectedFiles);
            return () => {
                selectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
            };
        }, [selectedFiles]);
        return (
            <Box>
                <Box
                    height="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={`${white}`}
                    px={2}
                >
                    <IconButton onClick={handleClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" style={{ margin: '0 auto', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        {`Upload Image`}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2 }}
                    >
                        {t('order.selectPhoto')}
                        <input
                            type="file"
                            hidden
                            multiple
                            accept="image/*"
                            onChange={handleFileSelect}
                        />
                    </Button>
                    <List>
                        {selectedFiles.map((file, index) => (
                            <ListItem
                                key={index}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Box
                                    component="img"
                                    src={file.preview}
                                    alt={`Preview of ${file.file.name}`}
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        minWidth: 50,
                                        minHeight: 50,
                                        objectFit: 'cover',
                                        marginRight: 2,
                                        borderRadius: 1
                                    }}
                                />
                                <Box>
                                    <Typography
                                        fontSize={'14px'}
                                        lineHeight={'16px'}
                                        sx={{
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {file.file.name}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            edge="start"
                                            aria-label="delete"
                                            onClick={() => handleDelete(index)}
                                            size="small"
                                            sx={{ mr: 1, p: 0 }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        <Typography fontSize={'12px'} lineHeight={'14px'}>
                                            {file.status === 'uploaded' ? '✅' : file.status}
                                        </Typography>
                                    </Box>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                    <Button
                        variant="contained"
                        onClick={handleUpload}
                        sx={{ mt: 1 }}
                    >
                        Upload
                    </Button>
                </Box>
            </Box>
        );
    }

    function ThreadDetail({ handleThreadClose }) {
        const [versionIndex, setVersionIndex] = useState(0)
        const [thread, setThread] = useState(null)
        const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const [moveToAnchorEl, setMoveToAnchorEl] = useState(null);
        const [openMessageDialog, setOpenMessageDialog] = useState(false);
        const [message, setMessage] = useState('');
        const [pendingNewState, setPendingNewState] = useState(null);
        const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
        const [messagesWithDisplayNames, setMessagesWithDisplayNames] = useState([]);
        const [isLoadingMessages, setIsLoadingMessages] = useState(true);

        useEffect(() => {
            if (thread?.messages) {
                setIsLoadingMessages(true);
                Promise.all(thread.messages.map(async (msg) => {
                    const displayName = await getUserDisplayName(msg.senderId, undefined, true);
                    return { ...msg, displayName };
                })).then((messages) => {
                    console.log('messages', messages);
                    setMessagesWithDisplayNames(messages);
                    setIsLoadingMessages(false);
                });
            }
        }, [thread]);

        // Add this useEffect to update the thread when orderData changes
        useEffect(() => {
            const updatedThread = getThreadById(openThreadId);
            setThread(updatedThread);
            if (updatedThread?.images?.length) {
                setVersionIndex(updatedThread?.images?.length - 1);
            }
        }, [orderData, openThreadId, threads]);

        const handleChange = (event) => {
            setInputValue(event.target.value);
        };

        const handleSend = async () => {
            setSendButtonDisabled(true);
            console.log('Send message:', inputValue);
            if (inputValue.trim() === '') return;
            const body = {
                action: 'sendMessage',
                actionRole: userData.userType,
                orderId: orderData.orderID,
                imageThreadId: openThreadId,
                content: inputValue
            }
            console.log('body', body);
            try {
                const response = await orderImageAction(body);
                console.log('response', response);
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.statusCode === 200) {
                    const newThreads = parsedResponse.body?.orderImageThreads;
                    setOrderData({ ...orderData, orderImageThreads: newThreads });
                    setThreads(newThreads ?? []);
                    setInputValue('');
                    // The thread will be updated automatically by the useEffect
                } else {
                    throw new Error(parsedResponse.message || 'Failed to send message');
                }
            } catch (error) {
                console.error('Error sending message:', error);
                // Handle error (e.g., show an error message to the user)
            } finally {
                setSendButtonDisabled(false);
            }
        };

        async function handleUploadInThread(e) {
            // this function is not optimized at all.
            const MAX_FILE_SIZE = 60 * 1024 * 1024; // 60 MB in bytes

            const newFiles = Array.from(e.target.files)
                .filter(file => file.type.startsWith('image/'))
                .filter(file => file.size <= MAX_FILE_SIZE)
                .map(file => ({
                    file,
                    status: 'To Do',
                    preview: URL.createObjectURL(file)
                }));

            const fileToBeUploaded = newFiles.filter(image => image.status !== 'uploaded');
            const formattedImages = await formatImages(fileToBeUploaded.map(file => file.file));
            const body = {
                action: 'uploadImageInThread',
                actionRole: userData.userType,
                orderId: orderData.orderID,
                imageThreadId: openThreadId,
                images: formattedImages
            }
            console.log('body', body);
            let uploadResponse = null;
            try {
                uploadResponse = await orderImageAction(body);
            } catch (error) {
                console.error('Error uploading images:', error);
            }
            try {
                uploadResponse = JSON.parse(uploadResponse);
                console.log('uploadResponse', uploadResponse);
                if (uploadResponse.statusCode === 200) {
                    // request complete, continue to upload the image itself file to s3
                    const uploadUrlsMap = uploadResponse.updateContent
                        ? uploadResponse.updateContent.reduce((map, image) => {
                            map[image.imageName] = image.uploadUrls;
                            return map;
                        }, {})
                        : {};
                    const updatedFiles = [...newFiles];
                    for (let imageIndex = 0; imageIndex < fileToBeUploaded.length; imageIndex++) {
                        const fileType = formattedImages[imageIndex].imageFileType;
                        const uploadUrl = uploadUrlsMap[formattedImages[imageIndex].imageName].imageUrl;
                        let uploadResult = false;
                        try {
                            uploadResult = await uploadFileToUrl(fileToBeUploaded[imageIndex].file, fileType, uploadUrl);
                        } catch (error) {
                            console.error('Error uploading images:', error);
                        }
                        const fileIndex = updatedFiles.findIndex(file => file.file === fileToBeUploaded[imageIndex].file);
                        if (fileIndex !== -1) {
                            updatedFiles[fileIndex].status = uploadResult ? 'uploaded' : 'Failed';
                        }
                    }
                }
                window.location.reload();
            } catch (error) {
                console.error('Error processing upload response:', error);
            }
        }

        const handleMoveToClick = (event) => {
            setMoveToAnchorEl(event.currentTarget);
        };

        const handleMoveTo = (newState) => {
            setPendingNewState(newState);
            setOpenMessageDialog(true);
            setMoveToAnchorEl(null);
        };

        const handleConfirmMove = async () => {
            if (canMoveImage(userData.userType, thread.state, pendingNewState)) {
                await updateOrderImageThreadsState([thread.threadId], pendingNewState, message,
                    () => {
                        callSnackbar(t('order.moveSuccess', { state: t(`order.status.${pendingNewState}`) }), 3000, 'success');
                        setOpenMessageDialog(false);
                        setMessage('');
                    },
                    () => {
                        callSnackbar(t('order.moveFailed'), 3000, 'error');
                        setOpenMessageDialog(false);
                    });
            } else {
                callSnackbar(t('order.moveFailedPermission'), 3000, 'error');
                setOpenMessageDialog(false);
            }
        };

        const handleCancelMove = () => {
            setOpenMessageDialog(false);
            setPendingNewState(null);
        };

        const handleVersionDecrease = () => {
            if (versionIndex > 0) {
                setVersionIndex(versionIndex - 1);
            }
        };

        const handleVersionIncrease = () => {
            if (versionIndex < thread?.images?.length - 1) {
                setVersionIndex(versionIndex + 1);
            }
        };

        const handleDownloadClick = (event) => {
            setDownloadAnchorEl(event.currentTarget);
        };

        const handleDownload = async (quality) => {
            try {
                const imageId = thread.images[versionIndex];  // Use versionIndex instead of the last index
                const image = getImageById(imageId);
                let url;
                if (quality === 'original') {
                    url = image['imageSignedUrl'];
                } else if (quality === 'thumbnail') {
                    url = image['thumbnailSignedUrl'] || image['imageSignedUrl'];
                }
                if (url) {
                    url = `${url}${url.includes('?') ? '&' : '?'}t=${Date.now()}`;
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const fileName = `p${thread.threadId}_v${versionIndex + 1}_${quality}.jpg`;  // Use versionIndex + 1 for the version number
                    await downloadBlob(blob, fileName);
                }
                setDownloadAnchorEl(null);
            } catch (error) {
                console.error(`Error downloading ${quality} quality image:`, error);
                // You might want to show an error message to the user here
            }
        };

        // Render the thread messages
        return (
            <Box>
                <Box
                    height="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={`${white}`}
                    pl={2}
                    pr={1}
                >
                    <Box display="flex" alignItems="center">
                        <IconButton onClick={handleThreadClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" ml={1}>
                            {t('order.photoIdAndVersion', { photoId: openThreadId, version: versionIndex + 1 })}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleVersionDecrease} disabled={versionIndex === 0}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <IconButton onClick={handleVersionIncrease} disabled={versionIndex === thread?.images?.length - 1}>
                            <ArrowRightIcon />
                        </IconButton>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={handleMoveToClick}
                        >
                            {t('constant.move')}
                        </Button>
                    </Box>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={getImageById(thread?.images?.[versionIndex])?.['imageSignedUrl']} alt={`Version ${versionIndex + 1}`}
                        style={{
                            maxHeight: 'calc(100vh - 104px)', maxWidth: '100vw', width: '100vw', objectFit: 'contain'
                        }} />
                </Box>

                <Box
                    height="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={`${white}`}
                    pl={2}
                    pr={1}
                >
                    <Box display="flex" alignItems="center">
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} gap={1}>
                        <IconButton onClick={handleVersionDecrease} disabled={versionIndex === 0}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <IconButton onClick={handleVersionIncrease} disabled={versionIndex === thread?.images?.length - 1}>
                            <ArrowRightIcon />
                        </IconButton>
                        {(orderData?.photographerUserName && orderData?.photographerUserName === userData?.Username) &&
                            <Button
                                variant="contained"
                                component="label"
                                size='small'
                            >
                                {t('order.uploadNewVersion')}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleUploadInThread}
                                />
                            </Button>
                        }
                        <Button
                            variant='contained'
                            size='small'
                            onClick={handleDownloadClick}
                        >
                            {t('constant.download')}
                        </Button>
                    </Box>
                </Box>
                <Box p={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography
                            fontSize={{ xs: '12px', md: '14px' }}
                            fontWeight={'bold'}
                        >
                            {`${(thread?.messages ?? []).length} ${t('constant.comment.comment')} `}
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                fontSize: { xs: '10px', md: '12px' },
                                color: `${pink[2]}`,
                                borderColor: `${pink[2]}`,
                                '&:hover': {
                                    borderColor: `${pink[3]}`,
                                    backgroundColor: `${pink[0]}`,
                                }
                            }}
                            disabled={true} // TODO: 修片指南
                        >
                            {t('order.imageEditingGuide')}
                        </Button>
                    </Box>
                    <Box>
                        {isLoadingMessages ? (
                            <Typography>Loading messages...</Typography>
                        ) : (
                            messagesWithDisplayNames.map((msg) => (
                                <Box key={msg.messageId} display="flex" alignItems="flex-start" mb={1}>
                                    <UserAvatar username={msg.senderId} sx={{ mr: 2, width: 30, height: 30 }}>
                                        {msg.displayName.charAt(0).toUpperCase()}
                                    </UserAvatar>
                                    <Box flexGrow={1}>
                                        <Typography color={`${grey[2]}`} fontSize={'12px'} fontWeight={'bold'}>
                                            {msg.senderId === orderData?.customerUserName ? '(Customer)' : ""}
                                            {msg.senderId === orderData?.photographerUserName ? '(Photographer)' : ""}
                                            {msg.displayName}
                                        </Typography>
                                        <Typography fontSize={'12px'}>
                                            {msg.content}
                                        </Typography>
                                        <Typography color={`${grey[2]}`} fontSize={'12px'}>
                                            {formatDate(msg.timestamp)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        )}
                    </Box>
                    <Box >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            p={'1px'}
                        >
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                value={inputValue}
                                onChange={handleChange}
                                multiline
                                maxRows={5} // 限制最大高度为5行
                                placeholder={t('constant.comment.placeholder')}
                                InputProps={{
                                    sx: {
                                        bgcolor: `${white[0]}`,
                                        padding: '4px 10px 4px 10px',
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(255,150,150,0.4)",
                                            borderRadius: '10px',
                                            border: `1px solid ${pink[1]}`,
                                        },
                                        "& .Mui-focused  fieldset": {
                                            borderColor: "#FF7A7A !important"
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            fontSize: { xs: '12px', md: '14px' },
                                            lineHeight: { xs: '12px', md: '14px' },
                                            color: `${black} !important`
                                        },
                                        "& label": {
                                            color: `${black}`
                                        },
                                        "& label.Mui-focused": {
                                            color: "#FF7A7A"
                                        }
                                    },
                                }}
                            />
                            <IconButton onClick={handleSend} color="primary" disabled={sendButtonDisabled}>
                                <img src={sendButton} alt="Send" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Menu
                    anchorEl={moveToAnchorEl}
                    open={Boolean(moveToAnchorEl)}
                    onClose={() => setMoveToAnchorEl(null)}
                >
                    {imageStates.filter(state => state !== thread?.state && canMoveImage(userData?.userType, thread?.state, state))
                        .map((state) => (
                            <MenuItem key={state} onClick={() => handleMoveTo(state)}>
                                {t(`order.status.${state}`)}
                            </MenuItem>
                        ))
                    }
                </Menu>
                <Dialog
                    open={openMessageDialog}
                    onClose={handleCancelMove}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{t('order.addMessage')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            label={t('order.message')}
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelMove}>{t('constant.cancel')}</Button>
                        <Button onClick={handleConfirmMove} variant='contained'>{t('order.confirmMove')}</Button>
                    </DialogActions>
                </Dialog>
                <Menu
                    anchorEl={downloadAnchorEl}
                    open={Boolean(downloadAnchorEl)}
                    onClose={() => setDownloadAnchorEl(null)}
                >
                    <MenuItem onClick={async () => await handleDownload('thumbnail')}>
                        {t('constant.thumbnail')}
                    </MenuItem>
                    <MenuItem onClick={async () => await handleDownload('original')}>
                        {t('constant.original')}
                    </MenuItem>
                </Menu>
            </Box>
        );
    }

    function ThreadList({ handleListClose }) {
        const [selectMode, setSelectMode] = useState(false);
        const [selectedThreads, setSelectedThreads] = useState([]);
        const [moveToAnchorEl, setMoveToAnchorEl] = useState(null);
        const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
        const [openMessageDialog, setOpenMessageDialog] = useState(false);
        const [message, setMessage] = useState('');
        const [pendingNewState, setPendingNewState] = useState(null);

        const handleSelectModeToggle = () => {
            setSelectMode(!selectMode);
            setSelectedThreads([]);
        };

        const handleThreadSelect = (threadId) => {
            if (selectMode) {
                setSelectedThreads(prev =>
                    prev.includes(threadId)
                        ? prev.filter(id => id !== threadId)
                        : [...prev, threadId]
                );
            } else {
                handleThreadClick(threadId);
            }
        };

        const handleMoveToClick = (event) => {
            setMoveToAnchorEl(event.currentTarget);
        };

        const handleMoveTo = (newState) => {
            setPendingNewState(newState);
            setOpenMessageDialog(true);
            setMoveToAnchorEl(null);
        };

        const handleConfirmMove = async () => {
            if (canMoveImage(userData.userType, selectedList, pendingNewState)) {
                await updateOrderImageThreadsState(selectedThreads, pendingNewState, message,
                    () => {
                        callSnackbar(`Move to ${pendingNewState} successfully`, 3000, 'success');
                        setSelectedThreads([]);
                        setOpenMessageDialog(false);
                        setMessage('');
                    },
                    () => {
                        callSnackbar('Failed to move to new state', 3000, 'error');
                        setOpenMessageDialog(false);
                    });
            } else {
                callSnackbar('You do not have permission to perform this action', 3000, 'error');
                setOpenMessageDialog(false);
            }
        };

        const handleCancelMove = () => {
            setOpenMessageDialog(false);
            setPendingNewState(null);
        };

        const handleDownloadClick = (event) => {
            setDownloadAnchorEl(event.currentTarget);
        };

        const handleDownload = async (quality) => {
            for (const threadId of selectedThreads) {
                const thread = threads.find(t => t.threadId === threadId);
                if (thread) {
                    try {
                        const imageId = thread.images[thread.images.length - 1];
                        const image = getImageById(imageId);

                        let url;
                        if (quality === 'original') {
                            url = image['imageSignedUrl'];
                        } else if (quality === 'thumbnail') {
                            url = image['thumbnailSignedUrl'] || image['imageSignedUrl'];
                        }
                        if (url) {
                            url = `${url}${url.includes('?') ? '&' : '?'}t=${Date.now()}`;
                            const response = await fetch(url);
                            const blob = await response.blob();
                            const fileName = `p${thread.threadId}_v${thread.images.length}_${quality}.jpg`;
                            await downloadBlob(blob, fileName);
                        }
                        setDownloadAnchorEl(null);
                    } catch (error) {
                        console.error(`Error downloading ${quality} quality images:`, error);
                        // You might want to show an error message to the user here
                    }
                }
            }
        };

        return (
            <Box display="flex" flexDirection="column" flex="1">
                <Box
                    height="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={`${white}`}
                    px={2}
                >
                    <IconButton onClick={handleListClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    {!selectMode &&
                        <Typography variant="h6" component="div" style={{ margin: '0 auto', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            {t(`order.status.${selectedList}`)}
                        </Typography>
                    }
                    {selectMode ? (
                        <Box display={'flex'} gap={1}>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={handleDownloadClick}
                                disabled={selectedThreads.length === 0}
                            >
                                {t('constant.download')}
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={handleMoveToClick}
                                disabled={selectedThreads.length === 0}
                            >
                                {t('constant.move')}
                            </Button>
                            <Button variant='outlined' size='small' onClick={handleSelectModeToggle}>{t('constant.cancel')}</Button>
                        </Box>
                    ) : (
                        <Button variant='outlined' size='small' onClick={handleSelectModeToggle}>{t('constant.select')}</Button>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    flex="1"
                    overflow="auto"
                    sx={{
                        '& > *': {
                            marginRight: 2,
                            marginBottom: 1,
                        },
                        '& > *:last-child': {
                            marginRight: 0,
                        }
                    }}
                    p={1}
                >
                    {threads?.filter(t => t.state === selectedList).map((thread, index) => (
                        <Box
                            key={index}
                            position="relative"
                            onClick={() => handleThreadSelect(thread.threadId)}
                        >
                            <Box
                                component="img"
                                src={getImageById(thread.images[thread.images.length - 1])['imageSignedUrl']}
                                alt={`Image ${index}`}
                                width={100}
                                height={100}
                                sx={{
                                    objectFit: 'contain',
                                    borderRadius: 0,
                                    backgroundColor: 'lightgray',
                                    border: selectMode && selectedThreads.includes(thread.threadId) ? '2px solid blue' : 'none',
                                }}
                            />
                            {selectMode && selectedThreads.includes(thread.threadId) && (
                                <Box
                                    position="absolute"
                                    top={5}
                                    right={5}
                                    width={20}
                                    height={20}
                                    borderRadius="50%"
                                    bgcolor="blue"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography color="white">✓</Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
                <Menu
                    anchorEl={moveToAnchorEl}
                    open={Boolean(moveToAnchorEl)}
                    onClose={() => setMoveToAnchorEl(null)}
                >
                    {imageStates.filter(state => state !== selectedList && canMoveImage(userData?.userType, selectedList, state))
                        .map((state) => (
                            <MenuItem key={state} onClick={() => handleMoveTo(state)}>
                                {t(`order.status.${state}`)}
                            </MenuItem>
                        ))
                    }
                </Menu>
                <Menu
                    anchorEl={downloadAnchorEl}
                    open={Boolean(downloadAnchorEl)}
                    onClose={() => setDownloadAnchorEl(null)}
                >
                    <MenuItem onClick={async () => await handleDownload('thumbnail')}>
                        {t('constant.thumbnail')}
                    </MenuItem>
                    <MenuItem onClick={async () => await handleDownload('original')}>
                        {t('constant.original')}
                    </MenuItem>
                </Menu>
                <Dialog
                    open={openMessageDialog}
                    onClose={handleCancelMove}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{t('order.addMessage')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            label={t('order.message')}
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelMove}>{t('constant.cancel')}</Button>
                        <Button onClick={handleConfirmMove} variant='contained'>{t('order.confirmMove')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    function OrderImageList({ handleListClick, handleUploadClick }) {
        return (
            <Box display={'flex'} flexDirection={'column'} gap={2}
                sx={{
                    alignItems: { xs: 'center', sm: 'center', md: 'flex-start' }, // Center on xs and sm, align left on md and above
                }}>
                {imageStates.map((imageState) => {
                    const threadsForState = (threads ?? []).filter(t => t.state === imageState);
                    const threadCount = threadsForState.length;

                    return (
                        <Box key={`${imageState}-image-list-card`}
                            sx={{ borderRadius: '8px', border: `2px solid ${pink[2]}`, height: '164px', width: '295px' }}
                        >
                            <Box
                                sx={{
                                    borderBottom: `2px solid ${pink[2]}`, height: '120px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    position: 'relative'
                                }}
                            >
                                <Typography position={'absolute'} left={12} top={6} fontSize={'14px'} lineHeight={'14px'} fontWeight={'medium'}>{t(`order.status.${imageState}`)}</Typography>
                                <Box sx={{ flexGrow: 1, textAlign: 'center', height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    {threadCount > 0 ? (
                                        <img
                                            src={getImageById(threadsForState[threadCount - 1].images[threadsForState[threadCount - 1].images.length - 1])?.['imageSignedUrl'] || logoWithText}
                                            alt={`Last for ${imageState}`}
                                            style={{ height: 'auto', width: `${164 / 295 * 100}%`, maxHeight: '100%', objectFit: 'contain' }}
                                        />
                                    ) : (
                                        <img src={logoWithText} alt="Center" style={{ height: '100%', width: '100%' }} />
                                    )}
                                </Box>
                                <Typography position={'absolute'} right={12} top={6} fontSize={'14px'} lineHeight={'14px'}>{threadCount}</Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} height="38px" width={'100%'} px={'12px'}>
                                {(imageState === 'photo' && userData?.userType === 'photographer') &&
                                    <Button variant='contained' onClick={handleUploadClick} size='small'
                                        sx={{ width: '70px', height: '28px', borderRadius: '2px', backgroundColor: `${pink[2]}` }} >
                                        {t('constant.upload')}
                                    </Button>}
                                <Button variant='contained' onClick={() => { handleListClick(imageState) }} size='small'
                                    disabled={threadCount === 0}
                                    sx={{
                                        marginLeft: 'auto',
                                        width: '70px',
                                        height: '28px',
                                        borderRadius: '2px',
                                        backgroundColor: `${pink[2]}`,
                                        '&:disabled': {
                                            backgroundColor: `${grey[3]}`,
                                            color: `${grey[2]}`
                                        }
                                    }}
                                >
                                    {t('constant.view')}
                                </Button>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        )
    }

    function OrderStepper() {
        const steps = {
            'paid': 0,
            'accepted': 1,
            'photoPicking': 2,
            'completed': 3
        };
        return (
            <Stepper activeStep={orderData?.status ? steps[orderData.status] : 0} alternativeLabel sx={{ mb: 1 }}>
                {Object.keys(steps).map((label) => (
                    <Step key={label}
                        sx={{
                            'svg.Mui-completed': { color: '#FF9696' },
                            'svg.Mui-active': { color: '#FF9696' },
                            'span.MuiStepLabel-label': { mt: 0, fontWeight: '700', color: `${grey[1]}`, fontSize: '12px' }
                        }}
                    >
                        <StepLabel>{t(`order.status${label.charAt(0).toUpperCase() + label.slice(1)}`)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )

    }
    function OrderAction({ order, userData, user, setOrderData }) {
        return (
            <Box>
                {
                    (order.status === 'paid' && userData.userType === 'photographer') &&
                    <Box>
                        <OrderActionButton
                            label={t('order.accept_order')}
                            user={user}
                            orderId={orderId}
                            updateOption={'photographer:accept'}
                            updateContent={'photographer:accept'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.accept_order_message')}
                    </Box>
                }
                {
                    (order.status === 'paid' && userData.userType === 'customer') &&
                    <Box>
                        <Button variant='contained' disabled={true}>
                            {t('order.accept_order')}
                        </Button>
                        {t('order.waiting_for_photographer_confirmation')}
                    </Box>
                }
                {
                    (order.status === 'accepted' && userData.userType === 'photographer') &&
                    <Box>
                        <OrderActionButton
                            label={order.confirmed_service_by_photographer ? t('order.service_confirmed') : t('order.confirm_service')}
                            user={user}
                            orderId={orderId}
                            disabled={order.confirmed_service_by_photographer}
                            updateOption={'photographer:confirm_service'}
                            updateContent={'photographer:confirm_service'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.waiting_for_both_confirm_service')}
                    </Box>
                }
                {
                    (order.status === 'accepted' && userData.userType === 'customer') &&
                    <Box>
                        <OrderActionButton
                            label={order.confirmed_service_by_customer ? t('order.service_confirmed') : t('order.confirm_service')}
                            user={user}
                            orderId={orderId}
                            disabled={order.confirmed_service_by_customer}
                            updateOption={'customer:confirm_service'}
                            updateContent={'customer:confirm_service'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.waiting_for_both_confirm_service')}
                    </Box>
                }
                <Box id='photoPicking-completed-action-container'>
                    {
                        (order.status === 'photoPicking' && userData.userType === 'customer') &&
                        <>
                            <OrderActionButton
                                label={t('order.complete_order')}
                                user={user}
                                orderId={orderId}
                                updateOption={'customer:complete'}
                                updateContent={'customer:complete'}
                                orderDataCallback={setOrderData}
                            />
                            {t('order.service_satisfied')}
                        </>
                    }
                </Box>
                <Box id='order-review-container'>
                    {
                        (order.status === 'completed' && userData.userType === 'customer' && !order.review) &&
                        <ReviewDrawer
                            onSubmit={handleReviewSubmit}
                            initialReviewData={reviewData}
                            order={order}
                        />
                    }
                    {
                        (order.status === 'completed' && order.review) &&
                        <Typography>{t('order.reviewCompleted')}</Typography>
                    }
                </Box>
            </Box>
        );
    }

    function OrderCard({ order }) {
        const [expand, setExpand] = useState(false);
        useEffect(() => {
            setExpand(order?.status !== "completed" && order?.status !== "photoPicking");
        }, [])
        if (order === undefined)
            return (<p>{t('loading')}</p>);
        return (
            <Box gap={1} display={'flex'} flexDirection={'column'}>
                <Box display="flex" flexDirection={'row'} gap={3} flexWrap="wrap" justifyContent={{
                    xs: 'space-between',
                    sm: 'space-between',
                    md: 'flex-start'
                }}>
                    <Typography fontSize={14} fontWeight={'medium'} color={blue[1]}>
                        {t('order.orderName', { studioName: order.studioName })}
                    </Typography>
                    {expand
                        ?
                        <Button variant='contained' size='small' sx={{ py: 0, color: `${white}` }}
                            onClick={() => {
                                setExpand(false);
                            }}
                        >
                            {t('order.collapse')}
                        </Button>
                        :
                        <Button variant='contained' size='small' sx={{ py: 0, color: `${white}` }}
                            onClick={() => {
                                setExpand(true);
                            }}
                        >
                            {t('order.showMore')}
                        </Button>
                    }

                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography fontSize={14} fontWeight="medium" mr={1}>
                        {t('order.orderID')}:
                    </Typography>
                    <CopyTypography fontSize={14} fontWeight={400} >
                        {order.orderID}
                    </CopyTypography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                        {t('order.orderStatus')}:
                    </Typography>
                    <Typography fontSize={14} fontWeight={400}>
                        {t(`order.status${order.status.charAt(0).toUpperCase() + order.status.slice(1)}`)}
                    </Typography>
                </Box>
                <Collapse in={expand}>
                    <Box id="expand-info-box" gap={1} display={'flex'} flexDirection={'column'}>
                        {order.time_reserved &&
                            <Box display="flex" alignItems="center">
                                <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                                    {t('order.photoServiceTime')}:
                                </Typography>
                                <Typography fontSize={14} fontWeight={400}>
                                    {formatDateRange(order.time_reserved.time_start, order.time_reserved.time_end)}
                                </Typography>
                            </Box>
                        }
                        <Box display="flex" alignItems="center">
                            <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                                {t('order.paidAmount')}:
                            </Typography>
                            <Typography fontSize={14} fontWeight={400}>
                                ${((order.charge?.amount_captured ?? 0) / 100).toFixed(2)}
                            </Typography>
                            {order.invoice?.hosted_invoice_url &&
                                <Button size='small' sx={{ p: 0, ml: 3, color: `${blue[1]}` }}
                                    onClick={() => {
                                        // close todo: drawer from bottom
                                        window.open(order.invoice.hosted_invoice_url, '_blank');
                                    }}
                                >
                                    {t('order.stripeReceipt')}
                                </Button>
                            }
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                                {t('order.serviceName')}:
                            </Typography>
                            <Typography fontSize={14} fontWeight={400}>
                                {order.product_list.map((product) => product.product_name).join(', ')}
                            </Typography>
                        </Box>
                        {order.orderNote &&
                            <Box display="flex" alignItems="center">
                                <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                                    {t('studio.orderNotes')}:
                                </Typography>
                                <Box sx={{ border: `solid 1px ${grey[3]}`, borderRadius: '8px', p: 2 }}>
                                    <Typography fontSize={10} fontWeight={400}>
                                        {order.orderNote}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Collapse>
            </Box >
        );
    }

}
export default OrderDetail;



function CustomizedBreadcrumbs() {
    const { t } = useTranslation();
    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/"
                    label={t('home')}
                    sx={{
                        ...pinkStyle,
                        cursor: 'pointer',
                    }}
                />
                <StyledBreadcrumb component="a" href="/order" label={t('order.allOrders')}
                    sx={{
                        ...pinkStyle,
                        cursor: 'pointer',
                    }} />
                <StyledBreadcrumb label={t('order.orderDetail')} sx={pinkStyle} />
            </Breadcrumbs>
        </div>
    );
}

function OrderActionButton({ label, user, orderId, updateOption, updateContent, orderDataCallback, disabled }) {
    const [actionButtonDisable, setActionButtonDisable] = useState(false);
    const callSnackbar = useSnackbar();

    return (
        <Button
            variant='contained'
            disabled={disabled || actionButtonDisable}
            onClick={async () => {
                setActionButtonDisable(true);
                try {
                    const updateResult = JSON.parse(await updateOrder(user, orderId, updateOption, updateContent));
                    if (updateResult.statusCode === 200) {
                        orderDataCallback(updateResult.order);
                        callSnackbar('Order updated successfully', 3000, 'success');
                    } else {
                        throw new Error(updateResult.message || 'Failed to update order');
                    }
                } catch (error) {
                    console.error('updateResult', error);
                    callSnackbar('Error updating order: ' + error.message, 3000, 'error');
                    setActionButtonDisable(false);
                }
            }}
        >
            {label}
        </Button>
    );
}


/**
 * Formats the start and end times into a human-readable date and time range.
 * If the start and end times fall on different dates, both dates will be displayed.
 *
 * @param {string} timeStart - The start time in ISO format (e.g., "2024-06-08T09:15:00").
* @param {string} timeEnd - The end time in ISO format (e.g., "2024-06-08T10:15:00").
* @returns {string} The formatted date and time range.
*
* @example
* // Same day
* const timeStart = "2024-06-08T09:15:00";
* const timeEnd = "2024-06-08T10:15:00";
* console.log(formatDateRange(timeStart, timeEnd));
* // Output: "6/8/2024, 9:15 AM - 10:15 AM"
*
* @example
* // Different days
* const timeStart = "2024-06-08T09:15:00";
* const timeEnd = "2024-06-09T10:15:00";
* console.log(formatDateRange(timeStart, timeEnd));
* // Output: "6/8/2024, 9:15 AM - 6/9/2024, 10:15 AM"
*/
function formatDateRange(timeStart, timeEnd) {
    const startDate = new Date(timeStart);
    const endDate = new Date(timeEnd);

    const optionsDate = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

    const formattedStartDate = new Intl.DateTimeFormat('en-US', optionsDate).format(startDate);
    const formattedEndDate = new Intl.DateTimeFormat('en-US', optionsDate).format(endDate);
    const formattedStartTime = new Intl.DateTimeFormat('en-US', optionsTime).format(startDate);
    const formattedEndTime = new Intl.DateTimeFormat('en-US', optionsTime).format(endDate);

    if (formattedStartDate === formattedEndDate) {
        return `${formattedStartDate}, ${formattedStartTime} - ${formattedEndTime}`;
    } else {
        return `${formattedStartDate}, ${formattedStartTime} - ${formattedEndDate}, ${formattedEndTime}`;
    }
}

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const isThisYear = date.getFullYear() === now.getFullYear();

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return isThisYear
        ? `${month}-${day}`
        : `${month}-${day}-${year}`;
}

// Add this function near the top of your component or in a separate utility file

function canMoveImage(userType, currentState, newState) {
    if (userType === 'photographer') {
        return true; // Photographers can move images between all states
    } else if (userType === 'customer') {
        if (currentState === 'photo' && newState === 'pendingEdit') {
            return true;
        } else if (currentState === 'pendingEdit' && newState === 'photo') {
            return true;
        } else if (newState === 'pendingEdit') {
            return true; // Customers can move any image to 'pendingEdit'
        }
    }
    return false;
}
