import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Button, Rating } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlatformHomeSearchTool from './PlatformHomeSearchTool';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slider.css"
import i1 from './img/platformHome/c1-min.png'
import i2 from './img/platformHome/c2-min.png'
import i3 from './img/platformHome/1.jpg'
import i4 from './img/platformHome/2.jpg'
import { getAllStudioData } from './apis';
// carousel image should be 2560 × 1492
const carouselImages = [i1, i2, i3, i4]

const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <ArrowForwardIosIcon fontSize="large" color='dark' />,
    prevArrow: <ArrowBackIosOutlinedIcon fontSize="large" color='dark' />,
};

const calculateAverageRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0;
    const totalRating = reviews.reduce((sum, review) => {
        return sum + review.rates.reduce((rateSum, rate) => rateSum + rate.rate, 0) / review.rates.length;
    }, 0);
    return (totalRating / reviews.length).toFixed(1);
};

export function StudioCard({ studio, visitStudioHandler }) {
    const [isHovered, setIsHovered] = useState(false);
    let infoText2 = "";
    if (studio.addressState && studio.addressCity)
        infoText2 = studio.addressCity + ', ' + studio.addressState;
    else if (studio.addressState)
        infoText2 = studio.addressState;
    else if (studio.addressCity)
        infoText2 = studio.addressCity;

    const fontSize = "12px";

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const avgRating = studio.review?.reduce((sum, r) => sum + r.rates.reduce((s, rate) => s + rate.rate, 0) / r.rates.length, 0) / (studio.review?.length || 1) || 0;
    const reviewCount = studio.review?.length || 0;

    return (
        <Box
            sx={{
                width: { xs: "291px", md: "291px", lg: "375px" }
            }}>
            <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    display: 'flex',
                    alignItems: 'center', // this centers the text vertically
                    justifyContent: 'center',
                    transition: 'transform 0.3s',
                    transform: isHovered ? 'scale(1.03)' : 'scale(1)',
                    cursor: "pointer",
                    height: { xs: "194px", md: "194px", lg: "250px" }
                }}
                position="relative"
                onClick={() => { visitStudioHandler(studio.studioName) }}
            >
                {studio.cover
                    ? <img
                        src={studio.cover}
                        style={{
                            borderRadius: '20px',
                            width: "100%",
                            height: "100%"
                        }}
                        alt='studio_cover'
                    />
                    : <Box style={{
                        borderRadius: '20px',
                        width: "100%",
                        height: "auto"
                    }}>No Cover Uploaded</Box>}
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    p={1}
                >
                    {isHovered &&
                        <Button
                            variant='contained'
                            size='small'
                            style={{
                                borderRadius: '50px',
                                background: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                border: 'none',
                            }}>
                            收藏
                        </Button>
                    }
                </Box>
                <Box
                    position="absolute"
                    top={0} right={0} p={1}
                >
                    {studio.logo
                        ? <img
                            src={studio.logo}
                            style={{
                                maxWidth: '36px', maxHeight: '36px', borderRadius: '20px', objectFit: 'cover', border: 'solid white 1px',
                                transition: 'transform 0.3s',
                                transform: isHovered ? 'scale(1.4)' : 'scale(1)',
                            }}
                            position='relative'
                            alt='studio_cover' />
                        : <></>}
                </Box>
            </Box>
            <Box>
                {/* text info area under the image  */}
                <Box margin={0}>
                    <Typography variant='h6' fontWeight={'bold'} noWrap overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{studio.studioName}</Typography>
                </Box>
                <Box margin={0} fullwidth='true'>
                    <Box style={{
                        width: '50%',
                        float: 'left',
                        boxSizing: 'border-box',
                        textAlign: 'left',
                    }}>
                        <Rating
                            name="star-rating"
                            value={parseFloat(avgRating)}
                            precision={0.1}
                            sx={{ "& .MuiRating-icon": { color: "#FF9696" } }}
                            readOnly
                            size='small'
                        />
                        <Typography noWrap overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={fontSize} display='inline-block'>
                            {avgRating.toFixed(1)} ({reviewCount})
                        </Typography>
                    </Box>
                    <Box style={{
                        width: '50%',
                        float: 'left',
                        boxSizing: 'border-box',
                        textAlign: 'right',
                    }}><Typography noWrap overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={fontSize}>
                            {infoText2}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}



function PlatformHomePage() {
    const [allStudioData, setAllStudioData] = useState([]);
    const navigate = useNavigate();
    let requestSent = false;
    function visitStudioHandler(studioName) {
        navigate("/at/" + studioName);
    }

    async function loadAllStudioData() {
        if (requestSent) return;
        requestSent = true;
        const response = await getAllStudioData();
        let dataObject = JSON.parse(response)['studioData'];
        dataObject = dataObject
            .filter((studio) => studio['./agreements/photographerAgreement_1.txt'])
            .sort((a, b) => (b.serviceData?.length ?? 0) - (a.serviceData?.length ?? 0));
        setAllStudioData(dataObject);
    }
    useEffect(() => {
        loadAllStudioData();
    }, []);

    return (
        <Box className='PlatformHomePage' >
            <Box id='SliderBox'
                style={{
                    padding: "auto",
                    margin: "auto",
                    width: "inherit",
                    maxWidth: "100vw",
                }}>
                {carouselImages.length === 0 ? <></> :
                    <Slider {...settings}>
                        {carouselImages.map((imageUrl, index) => (
                            <div key={"key-" + index}>
                                <img
                                    alt={'carousel image ' + index}
                                    src={imageUrl}
                                    style={{ width: '100%', maxWidth: '100%' }}
                                />
                            </div>))}
                    </Slider>
                }
            </Box>
            <Box
                sx={{ px: 1, padding: { xs: 0, md: 2 } }}
                className='pageContent'
                maxWidth='1920px'
            >
                <Box id='searchToolSection' width={'100%'}>
                    <PlatformHomeSearchTool />
                </Box>
                <Box id='studioSection' width={'100%'}>
                    < br />
                    < br />
                    <Grid container>
                        {allStudioData.map((studio, index) => (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={'studio-' + index} display='flex' justifyContent={'center'}>
                                <StudioCard studio={studio} visitStudioHandler={visitStudioHandler} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box >
    );
}
export default PlatformHomePage;
