import React, { useEffect, useState } from 'react';

import { Button, Box, TextField } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import { updateUserData } from '../apis';
import { getUserAndData } from '../User';

const badPattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");

function StudioNameForm({ user, submitCallback }) {

    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentArray, setDialogContentArray] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [studioName, setStudioName] = useState('');
    const [studioNameEditDisable, setStudioNameEditDisable] = useState(false);
    const [studioNameHelperText, setStudioNameHelperText] = useState('');
    const [photographerName, setPhotographerName] = useState('');
    const [photographerNameEditDisable, setPhotographerNameEditDisable] = useState(false);
    const [photographerNameHelperText, setPhotographerNameHelperText] = useState('');
    const [errors, setErrors] = useState({
        studioName: false,
        photographerName: false
    });
    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    function loadForm(form) {
        if (form.studioName) {
            setStudioName(form.studioName);
            setStudioNameEditDisable(true);
        }
        if (form.photographerName) {
            setPhotographerName(form.photographerName);
            setPhotographerNameEditDisable(true);
        }
    }

    async function handleStudioNameSubmit() {
        const trimmedStudioName = studioName.trim();
        if (!trimmedStudioName) {
            setErrors({ studioName: true });
            return;
        }
        if (badPattern.test(trimmedStudioName)) {
            setErrors({ studioName: true });
            setStudioNameHelperText('禁止输入特殊字符'); // Special characters are not allowed.
            return;
        }
        if (trimmedStudioName.length < 2 || trimmedStudioName.length >= 24) {
            setErrors({ studioName: true });
            setStudioNameHelperText('名字长度必须在2到23个字符之间'); // Name must be between 2 and 23 characters.
            return;
        }
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['studioName'] = trimmedStudioName;
        const apiResponse = JSON.parse(await updateUserData(user, userData, updatedUserData));
        if (apiResponse['statusCode'] === 200) {
            if (submitCallback) {
                submitCallback();
            } else {
                window.location.reload();
            }
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['error'])
        }
    }
    async function handlePhotographerNameSubmit() {
        const trimmedPhotographerName = photographerName.trim();
        if (!trimmedPhotographerName) {
            setErrors({ photographerName: true });
            return;
        }
        if (badPattern.test(trimmedPhotographerName)) {
            setErrors({ photographerName: true });
            setPhotographerNameHelperText('禁止输入特殊字符'); // Special characters are not allowed.
            return;
        }
        if (trimmedPhotographerName.length < 2 || trimmedPhotographerName.length >= 24) {
            setErrors({ photographerName: true });
            setPhotographerNameHelperText('名字长度必须在2到23个字符之间'); // Name must be between 2 and 23 characters.
            return;
        }
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['photographerName'] = trimmedPhotographerName;
        updatedUserData['displayName'] = trimmedPhotographerName;
        const apiResponse = JSON.parse(await updateUserData(user, userData, updatedUserData));
        if (apiResponse['statusCode'] === 200) {
            if (submitCallback) {
                submitCallback();
            } else {
                window.location.reload();
            }
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['error'])
        }
    }
    useEffect(() => {
        if (user === null) return;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            loadForm(userAndData.userData);
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (<Box id={'BasicInfoForm-Box'}>
        <SimpleDialog
            id="simple-dialog"
            open={dialogOpen}
            title={dialogTitle}
            content={dialogContentArray}
            onClose={() => { setDialogOpen(false) }}
        ></SimpleDialog>
        {init ? (
            <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                    这些信息在保存后将不能被修改
                    <TextField
                        label="工作室名字" // Studio Name
                        value={studioName}
                        disabled={studioNameEditDisable}
                        error={errors.studioName}
                        helperText={studioNameHelperText}
                        onChange={(e) => setStudioName(e.target.value)}
                        variant="outlined"
                        inputProps={{ maxLength: 23 }}
                        required />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={studioNameEditDisable}
                        onClick={handleStudioNameSubmit}
                    >
                        保存工作室名字
                    </Button>
                    <TextField
                        label="摄影师名字" // Your Photographer Name
                        value={photographerName}
                        disabled={photographerNameEditDisable}
                        error={errors.photographerName}
                        helperText={photographerNameHelperText}
                        onChange={(e) => setPhotographerName(e.target.value)}
                        variant="outlined"
                        inputProps={{ maxLength: 23 }}
                        required />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={photographerNameEditDisable}
                        onClick={handlePhotographerNameSubmit}
                    >
                        保存摄影师名字
                    </Button>
                </Box>
            </Box>)
            :
            (<>Loading ...</>)
        }
    </Box>
    );
}
export default StudioNameForm;