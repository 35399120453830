import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tab } from '@mui/material';
import MyStudioTimeSetting from './MyStudioTimeSetting';
import MyStudioTimeBooked from './MyStudioTimeBooked';

function TabPanel({ children, value, index }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box pt={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MyStudioTimeForm({ user }) {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label={t('mystudio.time.studioTimeTabs')}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label={t('mystudio.time.openTime')} {...a11yProps(0)} />
                    <Tab label={t('mystudio.time.overview')} {...a11yProps(1)} />
                    <Tab label={t('mystudio.time.bookedTime')} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <MyStudioTimeSetting user={user} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {t('mystudio.time.inDevelopment')}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MyStudioTimeBooked user={user} />
            </TabPanel>
        </Box>
    );
}

export default MyStudioTimeForm;