import React from "react";
import TableDragSelect from "react-table-drag-select";
import "./TimeTable.css";

export default class TimeTable48 extends React.Component {
    state = {
        cells: this.props.data
    };
    onChange = (cells) => {
        this.setState({ cells });
        this.props.changeHandler(cells);
    };

    render = () =>
        <TableDragSelect
            value={this.state.cells}
            onChange={cells => { this.onChange(cells); }}
        >
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
            <tr><td /><td /><td /><td /><td /><td /><td /></tr>
        </TableDragSelect>;
}