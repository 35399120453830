import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import DraggableImageList from '../DraggableImageList';
import { base64ToFile } from '../commonUtils';
import { getUserAndData } from '../User';
import { serviceUpdateSync, updateServiceData } from '../apis';

// i will name this upload step as imageUpload2withConfirm = iu2wc. 2 means using my new image system.
// and also name other two upload step as iu2 and iu1.
// iu2 example => OrderDetail.js.
// iu1 example => MystudioServiceSettingForm.js

function MyStudioCarousel({ user }) {

    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    const [carouselData, setCarouselData] = useState([]);
    const [originalCarouselData, setOriginalCarouselData] = useState([]);
    const [isImageQualityOriginal, setImageQualityOriginal] = useState(true);
    let requested = false;
    function carouselChangeHandler(newData) {
        setCarouselData(JSON.parse(JSON.stringify(newData)));
        console.log(newData);
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('carousel' in userAndData.userData) {
                console.log('carousel exists');
                setCarouselData(userAndData.userData.carousel);
                setOriginalCarouselData(userAndData.userData.carousel);
            } else {
                console.log('carousel is empty');
                setCarouselData([])
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    async function handleCarouselUpload() {
        // will handle delete from the backend.
        // will do a refresh after upload successfully.
        const newImages = carouselData.filter((image) => { return image.key })

        if (JSON.stringify(carouselData) === JSON.stringify(originalCarouselData)) {
            console.log('Nothing was changed at all.')
        }
        console.log("carouselData")
        console.log(carouselData)
        console.log("newImages")
        console.log(newImages)
        return
        /*
        const updateOption = 'photographer:uploadImages';
        const updateContent = JSON.stringify(newImages);// collect image name, type and its thumbnails
        // const apiResponse = JSON.parse(await updateOrder(user, orderId, updateOption, updateContent));
        console.log('apiResponse= ', apiResponse);
        if (apiResponse.statusCode === 200) {
            if (apiResponse['updateContent']) {
                console.log("updateContent", apiResponse['updateContent']);
                return apiResponse['updateContent']
                // return this for image upload next step
            } else {
                console.error('ERROR! Upload url is not received.');
            }
        }
        console.error('no images retrieved')
        */
    }

    return (
        <Box id={'MyStudioServiceSettingForm'}>
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                    <Typography>In dev plan, ETA: Aug 11</Typography>
                    <DraggableImageList
                        galleryList={carouselData.map((imageItem) => {
                            if (isImageQualityOriginal) {
                                return { ...imageItem, url: imageItem.imageSignedUrl || imageItem.url }
                            } else {
                                return { ...imageItem, url: imageItem.thumbnailSignedUrl || imageItem.imageSignedUrl || imageItem.url }
                            }
                        })}
                        onListChangeHandler={carouselChangeHandler}
                        saveButtonHandler={handleCarouselUpload}
                        saveButtonDisable={JSON.stringify(carouselData) === JSON.stringify(originalCarouselData)}
                    />
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyStudioCarousel;