import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const QuantityAdjuster = ({ quantity, setQuantity }) => {

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setQuantity(value);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Button variant="outlined" color="primary" onClick={handleDecrement}
        style={{ height: 40, borderColor: 'hsl(210, 10%, 58%)' }}>
        -
      </Button>
      <TextField
        type="number"
        variant="outlined"
        size='small'
        value={quantity}
        onChange={handleInputChange}
        style={{ margin: '0 0', width: 80, height: 40 }}
      />
      <Button variant="outlined" color="primary" onClick={handleIncrement}
        style={{ height: 40, borderColor: 'hsl(210, 10%, 58%)' }}>
        +
      </Button>
    </Box>
  );
};

export default QuantityAdjuster;