


import React from 'react';
import "./AboutPage.css";
import { Typography, Box } from '@mui/material';

export default function ColorCard({ color, first, second, third }) {
    const textColor = '#441B00'
    return (
        <Box bgcolor={color} sx={{ padding: { xs: 2, md: 6 }, height: { xs: 220, md: 370 } }}>
            <div style={{ textAlign: 'left' }}>
                <Typography color={textColor} fontFamily={'Heebo'} sx={{ fontSize: { xs: 14, md: 18 } }} variant="body1">{first}</Typography>
                <Typography color={textColor} fontFamily={'Heebo'} sx={{ fontSize: { xs: 28, md: 52 } }} fontWeight={900} variant="body1">{second}</Typography>
                <Typography color={textColor} sx={{ fontSize: { xs: 14, md: 20 } }} variant="body1">{third}</Typography>
            </div>
        </Box>
    )
}