// DraggableImageList.jsx

import { Box, Button, IconButton } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import { addRandomCharactersToFileName, filesToBase64Images, getAspectRatio, uploadFileToUrl } from './commonUtils';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';

const ImageCard = ({ src, title, id, index, moveImage, actionsHandler }) => {
    const ref = React.useRef(null);

    // for hover
    const [isHovered, setIsHovered] = React.useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    function deleteHandler() {
        actionsHandler(index, 'delete');
    }
    function downloadHandler() {
        actionsHandler(index, 'download');
    }

    const [, drop] = useDrop({
        accept: "image",
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveImage(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        type: "image",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging()
            };
        }
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <Box
            ref={ref}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ opacity }}
            className="card"
            border={'solid #80808066 1px'}
            width={200}
            height={200}
            display='flex'
            justifyContent='center'
            alignItems='center'
            position={"relative"}
            m={1}
        >
            <img
                src={src}
                alt={title}
                height={200}
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: 'auto',
                    height: 'auto',
                    alignSelf: 'center',
                }}
            />
            <Box
                position={"absolute"}
                right={0}
                bottom={0}
                display={isHovered ? "inline" : "none"}
            >
                <IconButton aria-label="delete" color="#ff0000" size="small" onClick={deleteHandler}>
                    <DeleteForeverIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="download" size="small" onClick={downloadHandler}>
                    <DownloadIcon fontSize="inherit" />
                </IconButton>
            </Box>
        </Box>
    );
};

function ButtonInput(props) {
    const { type, accept, multiple, onChangeHandler } = props;
    const inputRef = React.useRef();
    return (
        <Box>
            <input
                ref={inputRef}
                type={type}
                accept={accept}
                multiple={multiple}
                onChange={onChangeHandler}
                style={{ display: 'none' }}
            />
            <Button
                startIcon={<AddIcon />}
                variant='contained'
                width='auto'
                size='small'
                color='pink'
                onClick={() => {
                    inputRef.current.click()
                }}
            >
                Add Images
            </Button>
        </Box>
    );
}


const DraggableImageList = ({ galleryList, onListChangeHandler, uploadToOrder, saveButtonHandler, saveButtonDisable }) => {
    const [, setImages] = React.useState(galleryList);
    useEffect(() => {
        console.log('galleryList');
        console.log(galleryList);
    }, [galleryList])
    const moveImage = React.useCallback((dragIndex, hoverIndex) => {
        setImages((prevCards) => {
            const clonedCards = [...prevCards];
            const removedItem = clonedCards.splice(dragIndex, 1)[0];
            clonedCards.splice(hoverIndex, 0, removedItem);
            onListChangeHandler(clonedCards);
            return clonedCards;
        });// eslint-disable-next-line
    }, []);

    // conver input files uploaded to base64images
    async function onUploadChanged(e) {
        // Check if at least one file is selected
        if (e.target.files.length === 0) {
            // console.log('No file selected.');
            return;
        }
        const files = Array.from(e.target.files);
        const fileNames = files.map(file => addRandomCharactersToFileName(file.name, 30));
        const fileTypes = files.map(file => file.type);
        const urls = await filesToBase64Images(files);
        const aspectRatios = await Promise.all(files.map(file => getAspectRatio(file)));
        const newGalleryItems = files.map((file, index) => ({
            name: fileNames[index],
            url: urls[index],
            fileType: fileTypes[index]
        }));
        const galleryListCopy = [...JSON.parse(JSON.stringify(galleryList)), ...newGalleryItems];
        // console.log(galleryListCopy);
        if (uploadToOrder) {
            const newImages = files.map((file, index) => ({
                imageName: fileNames[index],
                imageFileType: fileTypes[index],
                imageSize: file.size,
                aspectRatio: String(aspectRatios[index])
            }));
            const updateContent = await uploadToOrder(newImages);
            console.log('debug2: updateContent' + JSON.stringify(updateContent));
            for (let imageIndex in files) {
                const fileName = fileNames[imageIndex]
                const fileType = fileTypes[imageIndex]
                let uploadUrl = ''
                for (const index in updateContent) {
                    if (updateContent[index].imageName === fileName) {
                        uploadUrl = updateContent[index].uploadUrls.imageUrl;
                        // console.log("uploadUrl: " + uploadUrl);
                        break;
                    }
                }
                const uploadResult = await uploadFileToUrl(files[imageIndex], fileType, uploadUrl);
                // console.log("uploadResult: " + uploadResult);
            }
        } else {
            // console.log('debug2: not to uplaod')
        }
        onListChangeHandler(galleryListCopy);
        setImages(galleryListCopy);
    }

    function actionsOnImage(imageIndex, action) {
        switch (action) {
            case 'delete':
                let galleryListCopy = [...galleryList];
                galleryListCopy.splice(imageIndex, 1);
                onListChangeHandler(galleryListCopy);
                setImages(galleryListCopy)
                return;
            case 'download':
                const image = galleryList[imageIndex];
                function extractAfterLastSlash(inputString) {
                    const lastSlashIndex = inputString.lastIndexOf('/');
                    if (lastSlashIndex !== -1) {
                        return inputString.substring(lastSlashIndex + 1);
                    } else {
                        return inputString;
                    }
                }
                function downloadImage(url, fileName) {
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                const imageUrl = image['url'];
                const imageName = extractAfterLastSlash(image['s3Path']);
                downloadImage(imageUrl, imageName);
                return;
            default:
                console.error('Internal error: unknown action on image');
                return;
        }

    }
    return (
        <Box>
            <Box display={'flex'} gap={1}>
                <ButtonInput
                    type="file"
                    accept="image/*"
                    multiple
                    onChangeHandler={
                        async (e) => {
                            await onUploadChanged(e);
                        }
                    } />
                {
                    saveButtonHandler &&
                    <Button
                        variant='contained'
                        size='small'
                        onClick={saveButtonHandler}
                    >
                        Save
                    </Button>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start'
            }}>
                <DndProvider backend={HTML5Backend}>
                    {galleryList.length !== 0 &&
                        galleryList.map((image, index) => (
                            <ImageCard
                                key={'cardkey-' + index}
                                src={image.url || image.thumbnailSignedUrl || image.imageSignedUrl}
                                index={index}
                                moveImage={moveImage}
                                actionsHandler={actionsOnImage}
                            />
                        ))
                    }
                </DndProvider>
            </Box>
        </Box>
    );
};
export default DraggableImageList;