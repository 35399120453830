import React, { useState, useEffect, useRef } from 'react';
import { Avatar } from '@mui/material';
import { getUsersAvatars } from './apis.js';

const UserAvatar = ({ userData, username, ...props }) => {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const fetchedRef = useRef(false);

    useEffect(() => {
        const fetchAvatarUrl = async () => {
            if (fetchedRef.current) return;
            let requestingName = username;
            if (!requestingName) requestingName = userData?.Username;
            if (!requestingName) return;
            fetchedRef.current = true;
            const avatarResult = JSON.parse(await getUsersAvatars([requestingName]));
            setAvatarUrl(avatarResult?.body?.avatars[0]);
        };

        fetchAvatarUrl();
    }, [username, userData]);

    return <Avatar src={avatarUrl} alt={username} {...props} />;
};

export default UserAvatar;
