import React, { useEffect, useState } from 'react';
import { Button, Box, AppBar, Toolbar, IconButton, Typography, Grid, Card, CardActionArea } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyStudioInfoForm from './MyStudioInfoForm';
import MyStudioTimeForm from './MyStudioTimeForm';
import MyStudioSocialMediaForm from './MyStudioSocialMediaForm';
import MyLogoAndCoverForm from './MyLogoAndCoverForm';
import MyStudioServiceSettingForm from './MyStudioServiceSettingForm';
import { getUserAndData } from '../User';
import MyStudioSummaryForm from './MyStudioSummaryForm';
import MyStudioCarousel from './MyStudioCarousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

function MyStudioPage() {
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [studioLink, setStudioLink] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    let requested = false;

    useEffect(() => {
        if (requested) return;
        requested = true;
        loadUser();
    }, [])

    async function loadUser() {
        let userAndData = await getUserAndData();
        console.log("New userdata method")
        setUser(userAndData.user);
        if (userAndData.userData.hasOwnProperty('studioName')) {
            setStudioLink('/at/' + userAndData.userData.studioName);
        }
    }

    const options = [
        { id: 'studioInfo', title: t('mystudio.studioAddress'), component: MyStudioInfoForm },
        { id: 'summaries', title: t('mystudio.studioIntroduction'), component: MyStudioSummaryForm },
        { id: 'logoAndCover', title: t('mystudio.logoAndCover'), component: MyLogoAndCoverForm },
        { id: 'socialMedia', title: t('mystudio.socialMediaSettings'), component: MyStudioSocialMediaForm },
        { id: 'serviceSetting', title: t('mystudio.serviceSettings'), component: MyStudioServiceSettingForm },
        { id: 'carouselSetting', title: t('mystudio.carouselSettings'), component: MyStudioCarousel },
        { id: 'manageTime', title: t('mystudio.serviceTimeSettings'), component: MyStudioTimeForm },
        { id: 'aiService', title: t('mystudio.aiServiceSettings'), component: null },
    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleBack = () => {
        setSelectedOption(null);
    };

    return (
        <Box className='MyStudioPage' sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            {selectedOption ? (
                <>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleBack} aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1 }}>
                                {selectedOption.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box padding={1}>
                        {selectedOption.component && React.createElement(selectedOption.component, { user: user })}
                        {!selectedOption.component && <Typography>{t('constant.comingSoon')}</Typography>}
                    </Box>
                </>
            ) : (
                <>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" style={{ flexGrow: 1 }}>
                                {t('mystudio.myStudio')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {options.map((option) => (
                            <Grid item xs={12} sm={6} md={4} key={option.id}>
                                <Card elevation={3}>
                                    <CardActionArea onClick={() => handleOptionSelect(option)}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                                {option.title}
                                            </Typography>
                                            <ArrowForwardIosIcon color="action" />
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                        {studioLink && (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    href={studioLink}
                                    sx={{ mt: 2 }}
                                >
                                    {t('mystudio.viewStudio')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Box>
    );
}

export default withAuthenticator(MyStudioPage);