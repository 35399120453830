import React, { useEffect, useState } from 'react';

import { Typography, Button, Box, TextField } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setStripeAccount } from '../apis';

function ProfileStripe({ user }) {
    let requested = false;
    const [init, setInit] = useState(false);
    const [stripeOnboardingUrl, setStripeOnboardingUrl] = useState('');
    const [onboarded, setOnboarded] = useState(false)
    const handleButtonClick = () => {
        window.open(stripeOnboardingUrl, '_blank');
    };

    useEffect(() => {
        if (!user) return;
        if (requested) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        requested = true;
        async function loadUserData() {
            const response = JSON.parse(await setStripeAccount(user));
            if (!('statusCode' in response)) {
                console.error('ERROR! No status Code in response.');
                return;
            }
            if (response['statusCode'] !== 200) {
                console.error('ERROR! Request to get Stripe Account Status failed.');
                console.log(response);
                return;
            }
            if (response['body']['status'] === 'onboarded') {
                console.log('status is onboarded. No action needed in this section.');
                setOnboarded(true);
                setInit(true);
                return;
            } else {
                setStripeOnboardingUrl(response['body']['url']);
                setInit(true);
            }
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <>
            {init
                ?
                (<Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={1} display='flex' flexDirection='column'>
                    {onboarded
                        ?
                        (<Box display="flex" alignItems="center">
                            <CheckCircleIcon color="success" fontSize="large" />
                            <Typography variant="h6" color="textPrimary" sx={{ marginLeft: 2 }}>
                                You are all set here!
                            </Typography>
                        </Box>)
                        :
                        (<Box>
                            <Typography>
                                可以选择直接联系客服收款或者设置Stripe收款账户（需要SSN）
                            </Typography>
                            <Typography>
                                请打开下方链接或者点击右侧按钮前往Stripe完成Stripe账号创建
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    value={stripeOnboardingUrl}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    sx={{ ml: 2, minWidth: '90px' }}
                                >
                                    打开链接
                                </Button>
                            </div>
                        </Box>)
                    }
                </Box>)
                :
                (<>Loading ...</>)
            }
        </>
    );
}
export default ProfileStripe;