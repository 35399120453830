/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "85c79e520f1a40d387328576c316be36",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "85c79e520f1a40d387328576c316be36",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://aqs4clpvtzfebopalhffmcfia4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-m5wf2qezebebnotfmhokqfic3i",
    "aws_cognito_identity_pool_id": "us-west-2:724396ec-a121-4125-b5b1-a9254d2e78c9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mdwXgAR1V",
    "aws_user_pools_web_client_id": "5tllh9q769lr8gjqkkdgl1f6kh",
    "oauth": {
        "domain": "pb59ar95xlbz-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.airyvibe.com/login",
        "redirectSignOut": "https://www.airyvibe.com/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
