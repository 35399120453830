import { getUser } from "./User";
const authLambdaStagingUrl = 'https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/userAuth'; // for prod
const authLambdaDevUrl = 'https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/userAuthDev'; // for dev
const authLambdaUrl = authLambdaStagingUrl; // make sure this is staging when push to main branch

async function sendRequest(payload, url) {
    let apiResponse = undefined;
    await fetch(url,
        {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            redirect: "follow", // manual, *follow, error
            body: JSON.stringify(payload),
        }).then(function (response) {
            return response.text();
        }).then(response => {
            apiResponse = response;
        })
        .catch(error => {
            console.error(error);
            apiResponse = null;
        });
    return apiResponse;
}

// userAuth and userAuthDev Route:


/**
 * unified api for orderImageAction. expecting body to be an object containing some of the following keys:
 * action: string
 * orderId: string
 * imageId: string
 * imageThreadId: string
 * actorRole: 'customer' or 'photographer'
 * @param {object} body
 * @returns {string} response in string
 */
export async function orderImageAction(body) {
    const payload = {
        path: 'orderImageAction',
        body: body,
        accessToken: (await getUser())?.signInUserSession?.accessToken?.jwtToken ?? null
    };
    return await sendRequest(payload, authLambdaUrl);

}

/**
 * upload avatar request
 * @returns {Promise<string>} response in string, containing signed url to upload avatar.
 */
export async function uploadAvatar(fileType) {
    const user = await getUser();
    const payload = { path: 'uploadAvatar', fileType, accessToken: user?.signInUserSession?.accessToken?.jwtToken ?? null };
    return await sendRequest(payload, authLambdaUrl);
}

/**
 * Get avatars for multiple users
 * @param {string[]} userIds - List of user IDs
 * @returns {Promise<string>} Response in string, containing signed URLs for avatars
 */
export async function getUsersAvatars(userIds) {
    const payload = { path: 'getUsersAvatars', userIds: userIds };
    return await sendRequest(payload, authLambdaUrl);
}

export async function setStripeAccount(user) {
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = { path: 'setStripeAccount', accessToken: user.signInUserSession.accessToken.jwtToken };
    return await sendRequest(payload, authLambdaUrl);
}

export async function getAllStudioOrders(user) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    const payload = { path: 'getAllStudioOrders', accessToken: user.signInUserSession.accessToken.jwtToken };
    return await sendRequest(payload, authLambdaUrl);
}

async function getAllCustomerOrders(user) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    const payload = { path: 'getAllCustomerOrders', accessToken: user.signInUserSession.accessToken.jwtToken };
    return await sendRequest(payload, authLambdaUrl);
}

async function getUserDataAPI(user) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    const payload = { path: 'getUserData', accessToken: user.signInUserSession.accessToken.jwtToken };
    return await sendRequest(payload, authLambdaUrl);
}

export async function getOrder(user, orderId, autoSignImages) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    const payload = { path: 'getOrder', accessToken: user.signInUserSession.accessToken.jwtToken, orderId: orderId, autoSignImages: autoSignImages !== undefined };
    let response = await sendRequest(payload, authLambdaUrl);
    // Parse the entire response if it's a string
    if (typeof response === 'string') {
        response = JSON.parse(response);
    }
    // If response is already an object, parse only the body if it's a string
    if (typeof response.body === 'string') {
        response.body = JSON.parse(response.body);
    }
    return response;
}

async function updateUserData(user, originalUserData, updatedUserData) {
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = {
        path: 'updateUserData',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        originalUserData: originalUserData,
        updatedUserData: updatedUserData
    };
    return await sendRequest(payload, authLambdaUrl);
}

async function updateUserDataByColumn(user, columnData, column) {
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = {
        path: 'updateUserDataByColumn',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        columnData: columnData,
        column: column
    };
    return await sendRequest(payload, authLambdaUrl);
}


/** update userdata by changed
 * example: itemChanged==={basicInfo:{addressCity:"Davis"}} to only update userdata.basicInfo.addressCity;
 */
async function updateUserDataByItemChanged(user, itemChanged) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    if (itemChanged === null || itemChanged === undefined || JSON.stringify(itemChanged) === '{}') {
        console.log("nothing changed, update request is not sent.");
        return { statusCode: 200 }
    }
    const payload = {
        path: 'updateUserDataByItemChanged',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        itemChanged: itemChanged
    };
    return await sendRequest(payload, authLambdaUrl);
}

async function signAgreement(user, agreementName) {
    const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
    console.log(currentTimestampInSeconds);
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = {
        path: 'signAgreement',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        agreementName: agreementName,
        timeSigned: currentTimestampInSeconds
    };
    console.log('signAgreement...');
    const response = await sendRequest(payload, authLambdaUrl);
    console.log('end of signAgreement');
    return response;
}

async function studioRegister(user) {
    const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
    console.log(currentTimestampInSeconds);
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = {
        path: 'studioRegister',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        timeRegister: currentTimestampInSeconds
    };
    const response = await sendRequest(payload, authLambdaUrl);
    return response;
}

async function serviceUpdateSync(user) {
    if (!user) {
        console.error('did not login yet')
        return;
    }
    const payload = {
        path: 'serviceUpdateSync',
        accessToken: user.signInUserSession.accessToken.jwtToken,
    };
    const response = await sendRequest(payload, authLambdaUrl);
    return response;
}

async function updateServiceData(user, updatedServiceData, dataVersion) {
    console.log(`updatedServiceData: ${JSON.stringify(updatedServiceData)}`);
    const payload = {
        path: 'updateServiceData',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        updatedServiceData: updatedServiceData,
        dataVersion: dataVersion
    };
    return await sendRequest(payload, authLambdaUrl);
}
// visitStudio Route

/**
 * You need to JSON.parse to get the data in array.
 * @returns {string} response in string
 */
export async function getAllStudioData() {
    const payload = { path: 'visit/getAllStudioData' };
    return await sendRequest(payload, authLambdaUrl);
}

/**
 * You need to JSON.parse to get the data in array.
 * @param {string[]} images
 * @returns {string} response in string
 */
export async function getImages(images) {
    const payload = { path: 'visit/getImages', images: images };
    return await sendRequest(payload, authLambdaUrl);
}

/**
 * You need to JSON.parse to get the data in array.
 * @param {string} studioName
 * @returns {string} response in string
 */
async function getStudioBookedTime(studioName) {
    const payload = { path: 'visit/getStudioBookedTime', studioName: studioName };
    return await sendRequest(payload, authLambdaUrl);
}

/**
 * You must JSON.parse twice to get the object.
 * @param {string} studioName
 * @param {boolean} autoSignImages true if wants to auto sign images.
 * @returns {string} response in string
 */
async function getStudioData(studioName, autoSignImages) {
    const payload = {
        path: 'visit/getStudioData',
        studioName,
        autoSignImages,
        accessToken: (await getUser())?.signInUserSession?.accessToken?.jwtToken ?? null
    };
    return await sendRequest(payload, authLambdaUrl);
}

/**
 * You must JSON.parse twice to get the object.
 * @param {string} studioName
 * @param {boolean} autoSignImages true if wants to auto sign images.
 * @returns {string} response in string
 */
export async function visitGetUserData(username) {
    const payload = {
        path: 'visit/getUserData',
        username: username
    };
    return await sendRequest(payload, authLambdaUrl);
}

async function getStudioLogoTime(studioName) {
    const payload = { path: 'visit/getStudioLogoTime', studioName: studioName };
    return await sendRequest(payload, authLambdaUrl);
}

// order Route

export async function updateOrder(user, orderId, updateOption, updateContent) {
    if (!user) {
        console.error('did not login yet');
        return;
    }
    const payload = {
        path: 'updateOrder',
        accessToken: user.signInUserSession.accessToken.jwtToken,
        orderId: orderId,
        updateOption: updateOption,
        updateContent: updateContent
    };
    console.log(payload) // TODO: remove log
    return await sendRequest(payload, authLambdaUrl);
}

export async function submitReview(user, orderId, reviewData) {
    const { qualityRating, guidingRating, communicationRating, review, selectedImages } = reviewData;
    const updateOption = 'customer:submitReview';
    const updateContent = JSON.stringify({
        qualityRating,
        guidingRating,
        communicationRating,
        review,
        selectedImages
    });
    try {
        const response = await updateOrder(user, orderId, updateOption, updateContent);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error submitting review:', error);
        throw error;
    }
}

export {
    getUserDataAPI,
    updateUserData,
    updateUserDataByColumn,
    serviceUpdateSync,
    updateServiceData,
    getAllCustomerOrders,
    getStudioBookedTime,
    getStudioData,
    getStudioLogoTime,
    signAgreement,
    studioRegister,
};