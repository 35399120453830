import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Card, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from './SimpleDialog';
import { getUserAndData } from './User';
import { useNavigate } from 'react-router-dom';
import { updateUserData } from './apis';
import { Auth } from 'aws-amplify';
import { Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LanguageToggle } from './Navbar/Navbar';

async function signOut() {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

// TODO: move this to App.js
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RegistrationPage() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const requestedRef = useRef(false);
    // snackbar for copy
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentArray, setDialogContentArray] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userType, setUserType] = useState(undefined);
    const [selection, setSelection] = useState('');
    const [borderColorCustomer, setBorderColorCustomer] = useState('#000000');
    const [borderColorPhotographer, setBorderColorPhotographer] = useState('#000000');
    const [buttonDisable, setButtonDisable] = useState(false);

    async function loadUser() {
        const userAndData = await getUserAndData();
        if (userAndData.user === null) {
            showErrorDialog(500, t('registrationPage.loadUserFailed'));
            setTimeout(() => {
                navigate('/login');
            }, 3000);
            return;
        }
        setUser(userAndData.user);
        if (userAndData.userData === null) {
            showErrorDialog(500, t('registrationPage.loadUserDataFailed'));
            return;
        }
        setUserData(userAndData.userData);
        setUserType(userAndData.userData.userType);
    }

    useEffect(() => {
        if (requestedRef.current) return;
        requestedRef.current = true;
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userType === 'photographer') {
            // this user has usertype, should just redirect
            const timer = setTimeout(() => {
                navigate('/mystudio');
            }, 100);
            return () => clearTimeout(timer); // Cleanup
        } else if (userType === 'customer') {
            // this user has usertype, should just redirect
            const timer = setTimeout(() => {
                navigate('/profile');
            }, 100);
            return () => clearTimeout(timer); // Cleanup
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType])

    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = t('order.errorCode', { code: errorCode });
        errorMessageArray.push(errorCodeMessage);
        setDialogContentArray(errorMessageArray);
        setDialogTitle(t('constant.error'))
        setDialogOpen(true)
    }
    function handleSelectCustomer() {
        setSelection('customer');
        setBorderColorCustomer("#ffd700");
        setBorderColorPhotographer("#000000");
    }
    function handleSelectPhotographer() {
        setSelection('photographer');
        setBorderColorCustomer("#000000");
        setBorderColorPhotographer("#ffd700");
    }
    async function handleSubmit() {
        setButtonDisable(true);
        let newData = JSON.parse(JSON.stringify(userData));
        newData["userType"] = selection;
        const rawApiResponse = await updateUserData(user, userData, newData);
        const apiResponse = JSON.parse(rawApiResponse);
        if (apiResponse['statusCode'] === 200) {
            // snackbar success
            handleClickSnackbar();
            setUserData(JSON.parse(JSON.stringify(apiResponse['body'])));
        }
        else {
            if (typeof apiResponse['body'] === 'string') {
                showErrorDialog(apiResponse['statusCode'], JSON.parse(apiResponse['body'])['error']);
            } else {
                showErrorDialog(apiResponse['statusCode'], apiResponse['body']['errorMessage']);
            }
        }
        setButtonDisable(false);
        loadUser();
    }

    return (
        <Box id={'RegistrationPage'}>
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {t('registrationPage.updateSuccess')}
                </Alert>
            </Snackbar>
            <Box textAlign={'right'}>
                <LanguageToggle />
                <Button variant='contained'
                    onClick={() => { signOut() }}> <Logout></Logout>{t('navbar.logout')}</Button>
            </Box>
            <Box textAlign={'center'}>
                <Typography variant='h1' > {t('registrationPage.welcome')} </Typography>
            </Box>
            <Box id={'select-account-type-box'}>
                <Box textAlign={'center'}>
                    <h4> {t('registrationPage.accountTypeQuestion')} </h4>
                    <p> {t('registrationPage.accountTypeWarning')} </p>
                </Box>
                <Box display={'flex'} justifyContent={'center'} gap={4}>
                    <Card
                        onClick={handleSelectCustomer}
                        style={{
                            border: 'solid',
                            borderColor: borderColorCustomer
                        }}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                                transform: 'scale(1.04)',
                            },
                            padding: 1,
                            transition: '0.3s', borderRadius: '12px',
                            height: { xs: "350px", lg: "400px" },
                            width: { xs: "250px", lg: "400px" },
                            textAlign: 'center'
                        }}>
                        <h4>{t('constant.customer')}</h4>
                        <Box sx={{ textAlign: 'left' }}>
                            <ul>
                                <li> {t('registrationPage.customerBenefit1')} </li>
                                <li> {t('registrationPage.customerBenefit2')} </li>
                                <li> {t('registrationPage.customerBenefit3')} </li>
                            </ul>
                        </Box>
                    </Card>
                    <Card
                        onClick={handleSelectPhotographer}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                                transform: 'scale(1.04)',
                            },
                            padding: 1,
                            transition: '0.3s', borderRadius: '12px',
                            height: { xs: "350px", lg: "400px" },
                            width: { xs: "250px", lg: "400px" },
                            textAlign: 'center'
                        }}
                        style={{
                            border: 'solid',
                            borderColor: borderColorPhotographer
                        }}>
                        <h4>{t('constant.photographer')}</h4>
                        <Box sx={{ textAlign: 'left' }}>
                            <ul>
                                <li> {t('registrationPage.photographerBenefit1')} </li>
                                <li> {t('registrationPage.photographerBenefit2')} </li>
                                <li> {t('registrationPage.photographerBenefit3')} </li>
                            </ul>
                        </Box>
                    </Card>
                </Box>
                <Box display={'flex'} justifyContent={'center'} margin={4}>
                    <Button
                        variant='contained' disabled={selection === "" || buttonDisable}
                        onClick={handleSubmit}
                    > {t('constant.confirm')} </Button>
                </Box>
            </Box>
        </Box>
    );
}
export default RegistrationPage;
