
import React, { useState } from 'react';
import "./AboutPage.css";
import {
    Stack, Typography, Button, Snackbar, Box, TextField, Avatar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import wechatImg from '../img/ronWechat.png';
import ImgDialog from '../ImgDialog/ImgDialog';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { getUserID } from '../commonUtils/getUserID';
import FAQComponent from '../FAQ';
import { email, ronWechat } from '../../constants/socials';
import ronPicture from '../img/profilePictures/ron.pic.jpg';
import xiPicture from '../img/profilePictures/xi.pic.jpg';
import alicePicture from '../img/profilePictures/alice.pic.jpg';
import ButtonWingLeft from './ButtonWingLeft.svg';
import ButtonWingRight from './ButtonWingRight.svg';
import ButtonArrowRight from './ButtonArrowRight.svg';
import CoverImg from './cover.svg';
import ColorCard from './ColorCard';
import { useNavigate } from 'react-router-dom';
import CopyTypography from '../../widgets/CopyTypography/CopyTypography';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AboutPage() {
    const navigate = useNavigate();
    // image dialog related
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDialogSrc, setimageDialogSrc] = useState("");
    let imgDialogHandleClose = () => { setImageDialogOpen(false) };
    const callImageDialog = (event) => {
        if (event.target.tagName !== 'IMG') return;
        if (imageDialogOpen) return;
        const src = event.target.src;
        setimageDialogSrc(src);
        setImageDialogOpen(true);
    };
    // contact us form
    function ValidateEmail(input) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (input.match(validRegex)) {
            return true;
        }
        return false;
    }
    const [formName, setFormName] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formMessage, setFormMessage] = useState('');
    function handleContactUsForm() {
        if (!formName || !formEmail || !formMessage) {
            alert('form is incomplete')
            return;
        }
        if (!ValidateEmail(formEmail)) {
            alert("Invalid email address!");
            return;
        }
        const requestBody = {
            "userName": formName,
            "userEmail": formEmail,
            "userMessage": formMessage,
            "userID": getUserID()
        };
        fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/contactus',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(requestBody), // body data type must match "Content-Type" header

            }).then(function (response) {
                return response.text();
            }).then(response => {
                handleClickSnackbarOnFormSuccess();
                setFormName();
                setFormEmail();
                setFormMessage();
            })
            .catch(error => {
                console.error(error);
                alert("发送失败，遇到了一些问题。");
            });
    }
    // snackbar for form
    const [openSnackbarOnFormSuccess, setOpenSnackbarOnFormSuccess] = useState(false);
    const handleClickSnackbarOnFormSuccess = () => {
        setOpenSnackbarOnFormSuccess(true);
    };
    const handleCloseSnackbarOnFormSuccess = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbarOnFormSuccess(false);
    };

    return (
        <div className='AboutPage'>
            <ImgDialog src={imageDialogSrc} open={imageDialogOpen} handleClose={imgDialogHandleClose} />
            <Snackbar open={openSnackbarOnFormSuccess} autoHideDuration={4000} onClose={handleCloseSnackbarOnFormSuccess}>
                <Alert onClose={handleCloseSnackbarOnFormSuccess} severity="success" sx={{ width: '100%' }}>
                    已发送，我们将以邮件形式回复您！
                </Alert>
            </Snackbar>
            <Box >
                <img src={CoverImg} alt="pink thread" style={{ width: '100vw' }} />
            </Box>
            <Stack sx={{ px: 1 }} className='pageContent'>
                <Typography fontWeight={700} fontSize={24} variant="body1" mb={2}>
                    平台介绍
                </Typography>

                <PlatformIntroComponent />
                <Typography fontWeight={700} fontSize={24} variant="body1" >
                    我们平台的功能和优势
                </Typography>
                <Grid container spacing={2} padding={3}>
                    <Grid xs={12} md={6}>
                        <ColorCard
                            color={'#FFE586'}
                            first={'Sales Diversion'}
                            second={'智能的销售引流渠道'}
                            third={'平台负责投流和市场推广帮助您增加曝光度并吸引更多潜在客户浏览摄影师专属页面'}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <ColorCard
                            color={'#FFF2E9'}
                            first={'Comment System'}
                            second={'用户为中心的评论系统'}
                            third={'记录客户的真实评论和客片分享，了解用户需求，帮助吸引潜在客户，提高转化率'}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <ColorCard
                            color={'#FFF2E9'}
                            first={'System and AI Assistant'}
                            second={'订单系统管理& AI 客服助手'}
                            third={'通过整合订单系统管理、付款下单、合同自动化、预约管理修片沟通等功能，并辅以AI客服自动解答常见问题，可提升摄影师工作效率'}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Box bgcolor={'#FF8B8B'} sx={{ padding: { xs: 2, md: 6 }, height: { xs: 220, md: 370 } }}
                            position="relative" display="flex" justifyContent="center" alignItems="center">
                            <div style={{ textAlign: 'left' }}>
                                <Typography color={'white'} textAlign={'center'} sx={{ fontSize: { xs: 28, md: 52 } }} fontWeight={400} variant="body1" fontFamily={"Baloo2-ExtraBold"}>{'Join AIRYVIBE'}</Typography>
                                <Typography color={'white'} textAlign={'center'} sx={{ fontSize: { xs: 14, md: 18 } }} fontWeight={700} variant="body1">{'平台附加产品和服务（如相片打印、定制相框）销售额的分成给摄影师'}</Typography>
                                <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                                    <img src={ButtonWingLeft} alt='no' />
                                    <Box width={16}></Box>
                                    <Button style={{ borderRadius: 50, backgroundColor: '#ffffff', textTransform: 'none' }} variant="contained"
                                        onClick={() => { navigate('/login') }}>
                                        <Box width={16}></Box>
                                        <Typography fontWeight={700} fontFamily={'Heebo'} fontSize={20} color={'#FF8B8B'}>{'Sign up for free'}</Typography>
                                        <Box width={16}></Box>
                                        <img src={ButtonArrowRight} alt='no' />
                                        <Box width={16}></Box>
                                    </Button>
                                    <Box width={16}></Box>
                                    <img src={ButtonWingRight} alt='no' />
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Box bgcolor={'#FFF2E7'} sx={{ padding: { xs: 2, md: 6 } }}>
                    <div style={{ textAlign: 'left' }}>
                        <Typography fontWeight={700} fontSize={24} variant="body1" >{'服务商入驻方式'}</Typography>
                        <Typography fontSize={20} component="div">
                            <ol>
                                <li>服务商注册：用邮箱或者电话号码注册账号，填写基础信息包括工作室名称，摄影师名称</li>
                                <li>展示页装饰：</li>
                                <ul>
                                    <li>工作室信息: 包括简介和地址</li>
                                    <li>上传Logo和封面图</li>
                                    <li>服务设置：设置服务内容，价格，和样品</li>
                                    <li>服务时间设置：填写未来七天的可预约时间</li>
                                    <li>AI客服设置(待开发)：可以放入FAQ或者知识库给店铺专属AI客服用于回答客户问题</li>
                                    <li>激活：激活店铺能让客户在搜索页看到店铺，取消激活可以让店铺暂时下线</li>
                                </ul>
                            </ol>
                        </Typography>
                    </div>
                </Box>

                <Box bgcolor={'#FFE586'} padding={6}>
                    <div style={{ textAlign: 'left' }}>
                        <Typography fontWeight={700} fontSize={24} variant="body1" >{'支付和转账方式'}</Typography>
                        <Typography fontSize={20} component="div">
                            <ul>
                                <li>终端客户下单会通过Stripe支付到AIRYVIVE账户里，AIRYVIBE会在扣除相关费用后在每个月结束后的15天内转账到摄影师指定账号里。Stripe 是一家提供在线支付处理解决方案的科技公司，Stripe的交易系统采取了多重措施来确保交易安全，保护商家和消费者的信息不受侵犯。</li>
                                <li>平台收取的费用：15%的净销售额，用于支付Stripe的交易费用以及平台的推广和运营成本。</li>
                            </ul>
                        </Typography>
                    </div>
                </Box>
                <Typography fontWeight={700} fontSize={24} variant="body1" >
                    联系我们
                </Typography>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Stack
                            alignItems="left"
                            alignContent="left"
                            textAlign='left'
                            direction="column"
                        >
                            <CopyTypography copyContent={ronWechat}>
                                微信/Wechat: {ronWechat}
                            </CopyTypography>
                            <CopyTypography copyContent={email}>
                                Email: {email}
                            </CopyTypography>
                            <Box>
                                <Grid mt={1} container spacing={1}>
                                    <Grid xs={12}>
                                        <Typography variant="h6" display="in-line">
                                            联系我们 - Contact Us
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="form--name"
                                            value={formName}
                                            onChange={(event) => { setFormName(event.target.value) }}
                                            label="名字"
                                        />
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="form--email"
                                            value={formEmail}
                                            onChange={(event) => { setFormEmail(event.target.value) }}
                                            label="Email"
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="form--message"
                                            value={formMessage}
                                            onChange={(event) => { setFormMessage(event.target.value) }}
                                            label="信息内容"
                                        />
                                    </Grid>
                                    <Grid xs={12} textAlign='center'>
                                        <Button variant='contained' onClick={handleContactUsForm} >提交表格</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Stack
                            alignItems="center"
                            alignContent="center"
                            direction="column"
                        >
                            微信
                            <img width="50%" src={wechatImg} alt='微信二维码' onClick={callImageDialog}></img>
                        </Stack>
                    </Grid>
                </Grid>
                <Typography fontWeight={700} fontSize={24} variant="body1" >
                    团队介绍
                </Typography>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12}>
                        <Stack
                            alignItems="left"
                            alignContent="left"
                            textAlign='left'
                            direction="column"
                        >
                            <PeopleIntro imageSource={xiPicture} title={"开发工程师：Xi Huang"} description={"// TODO: {Your intro here}."} />
                            <PeopleIntro imageSource={alicePicture} title={"设计师：Alice Liu"} description={"Alice是一位经验丰富的UI/UX高级设计师，专注与互联网AI产品设计，在AIRYVIBE担任首席产品设计师职位，负责AIRYVIBE的平台的设计战略，用户体验设计和界面设计。Alice拥有9年以上设计工作经验，曾为多家互联网软件开发公司负责公司产品全链路的设计，统筹，执行工作。设计领域包括电商，广告， 时尚，传媒，医疗等领域的互联网产品。Alice目前正在攻读Ph.D.学位，专注于AI数据分析领域的研究，并拥有UI/UX设计研究生和视觉传达设计本科的学习背景。她的个人作品被中央美术学院美术馆永久收藏并出版发表。"} />
                            <PeopleIntro imageSource={ronPicture} title={"市场营销总监：Ron Huang"} description={"Ron目前在AIRYVIBE负责平台内容架构的总设计和策划。Ron不仅拥有一家摄影工作室，还创立了AIRYVIBE的摄影平台，希望通过科技的力量为了摄影师提高个人品牌力，提高与客户的沟通效率。同时帮助客户找到更匹配的摄影师，并享受更便利，优质的服务。Ron还拥有多年数据科学分析，数据挖掘的工作经验，除了长期为电商公司负责财务分析，数据分析，数据库搭建，项目管理等方面，同时用数据驱动，人工智能技术促进业务销售增长，实现企业运营目标的达成。"} />
                        </Stack>
                    </Grid>
                </Grid>
                <FAQComponent />
            </Stack>
        </div >
    );
}

function PlatformIntroComponent() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography paragraph>
                AIRYVIBE平台作为连接摄影师和终端客户之间的桥梁，不仅为摄影师提供了一个展示自己的页面，帮助摄影师通过样片和真实客户评论来吸引更多潜在客户，还通过订单管理系统简化摄影师的工作流程。让摄影师能够更专注于创作和提升自己，同时轻松地吸引客户和管理订单。平台通过私域流量，Google广告，以及各种社交媒体，如小红书专业广告号，微信公众号，Instagram，Facebook等吸引有拍摄需求的客户。让客户在平台上根据其具体需求匹配选择合适的摄影师。
            </Typography>
        </Box>
    );
}

function PeopleIntro({ imageSource, title, description }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1,
            }}
        >
            <Avatar
                sx={{
                    width: 120,
                    height: 120,
                    marginRight: 2,
                }}
                src={imageSource}
                alt="头"
            />
            <Box>
                <Typography variant="h4" display="in-line" sx={{ mt: 2 }} fontWeight={700}>
                    {title}
                </Typography>
                <Typography variant="h6" display="in-line" fontWeight={400} >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {description}
                </Typography>
            </Box>
        </Box>
    );
}