import { Auth } from 'aws-amplify';
import { getUserDataAPI } from './apis';

async function getUser() {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (error) {
        console.error(error);
        return null;
    }
}
// return null on error
async function getUserData(user) {
    try {
        const rawUserData = await getUserDataAPI(user);
        return JSON.parse(rawUserData).body[0];
    } catch (error) {
        console.error(error);
        return null;
    }
}
/**
 * Represents an example object with attributes.
 * @typedef {Object} userAndData
 * @property {Object | null} user - The name attribute.
 * @property {Object | null} userData - The age attribute.
 */

/**
 *
 * @returns {Promise<userAndData>} - User and user data.
 */
async function getUserAndData() {
    const user = await getUser();
    if (user === null) {
        return { user: null, userData: null };
    }
    const userData = await getUserData(user);
    return { user: user, userData: userData };
}

export {
    getUserAndData,
    getUserData,
    getUser
}
