import React from 'react';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { copyText } from '../../components/commonUtils/copy';
import { useTranslation } from 'react-i18next';
import airyVibeTheme from '../../airyVibeTheme';

import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CopyTypography = ({ children, copyContent, ...props }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleCopy = () => {
        copyText(copyContent ? copyContent : children);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'start' }}>
            <Typography {...props}>
                {children}
            </Typography>
            <IconButton sx={{ padding: '2px' }}
                size={'small'} onClick={handleCopy} aria-label="copy">
                <ContentCopyIcon fontSize="small" />
            </IconButton>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    color={'primary'}
                    sx={{
                        width: '100%',
                        color: `${airyVibeTheme.palette.white.main}`
                    }}>
                    {t('constant.CopyTypographySuccessful')}
                </Alert>
            </Snackbar>
        </div >
    );
};

export default CopyTypography;
