function copyText(text) {
    const textString = text.toString();
    let input = document.querySelector('#copy-input');
    if (!input){
        input = document.createElement('input');
        input.id = 'copy-input';
        input.readOnly = 'readOnly';
        input.style.position = 'absolute';
        input.style.left = '-10000px';
        input.style.zIndex = '-1000';
        document.body.appendChild(input);
    }
    input.value = textString;
    selectText(input, 0, textString.length);
    document.execCommand('copy');
    input.blur();
}

function selectText(textbox, startIndex, stopIndex) {
    if(textbox.createTextRange) {
        const range = textbox.createTextRange();
        range.collapse(true);
        range.moveStart('character', startIndex);
        range.moveEnd('character', stopIndex-startIndex);
        range.select();
    } else {
        textbox.setSelectionRange(startIndex, stopIndex);
        textbox.focus();
    }
}

export {copyText};